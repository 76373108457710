import { useState } from "react"
import { useSelector } from "react-redux"
import Spacebox from "./styles/Spacebox"
import Flexbox from "./Flexbox"
import { Typography } from "@mui/material"
import CustomGrid from "./styles/Grid"
import CustomButton from "./styles/Custombutton"
import { CheckBoxRounded, CloseRounded } from "@mui/icons-material"
import { isMobile } from "react-device-detect"

const PricingBoxes = () => {

    const user = useSelector(state => state.user.value)

    const [currentTab, setCurrentTab] = useState(0)

    const navigators = [
        {
            name: 'Monthly',
        },
        {
            name: 'Annually',
        },
    ]

    const lists = [
        {
            content: "Contract creation: 20",
            has: true
        },
        {
            content: "Plugins access",
            has: true
        },
        {
            content: "Google sheets Integration",
            has: true
        },
        {
            content: "Download contract (Each Signatory)",
            has: true
        },
        {
            content: "Customer Support",
            has: true
        },
        {
            content: "Email Notification",
            has: true
        },
        {
            content: "Portal",
            has: true
        },
        {
            content: "Contract real time tracking",
            has: false
        },
        {
            content: "Priority Support",
            has: false
        },
    ]

    const basicSub = () => {
        if (currentTab === 0)
            window.location.href = 'https://buy.stripe.com/fZe8yW0QU57pbn28wx?prefilled_email=' + user.email
        else
            window.location.href = 'https://buy.stripe.com/eVa16ufLOgQ72QwcMP?prefilled_email=' + user.email
    }

    const proSub = () => {
        if (currentTab === 0)
            window.location.href = 'https://buy.stripe.com/aEU9D08jm43lcr6fZ0?prefilled_email=' + user.email
        else
            window.location.href = 'https://buy.stripe.com/aEU7uS1UYbvN2QwaEI?prefilled_email=' + user.email
    }
    return (
        <div className="pricing-boxes">
            <Flexbox justifyContent="center">
                <div className="navigators">
                    <Flexbox alignItems="center" >
                        {navigators.map((nav, index) => (
                            <div style={{ backgroundColor: index === currentTab && 'white', boxShadow: index === currentTab && 'rgba(86, 97, 107, 0.1) 0px 1px 2px 0px' }} className="cust-btn" key={index} onClick={() => setCurrentTab(index)}>
                                <span style={{ color: index === currentTab ? '#131316' : '#56616B' }}>{nav.name}</span>
                            </div>
                        ))}
                    </Flexbox>
                </div>
            </Flexbox>
            <Spacebox padding="20px" />
            <div>
                <CustomGrid grid={isMobile ? "1" : "2"} gap="20px">
                    <div style={{ borderRadius: "20px" }} className="neumorphisim">
                        <Spacebox padding="20px">
                            <Typography style={{ textAlign: 'center' }}>
                                Basic
                            </Typography>
                            <Typography style={{ textAlign: 'center', fontSize: '50px', fontWeight: 700 }}>
                                {currentTab === 0 ? "$20" : "$200"} <small style={{ fontSize: '0.4em', opacity: .7, fontWeight: 300 }}>/{currentTab === 0 ? "monthly" : "annually"}</small>
                            </Typography>
                            <Spacebox padding="10px" />
                            <CustomButton backgroundColor="var(--primary)" color="white" borderRadius="100px" padding="15px 0px" className="fullwidth" handleClick={() => basicSub()}>
                                Subscribe
                            </CustomButton>
                            <Spacebox padding="5px" style={{ textAlign: 'center' }}>
                                <small style={{ color: 'red' }}>Endeavor to keep your email consistent when making payment. your email is {user.email}</small>
                            </Spacebox>
                            <Spacebox padding="10px" />
                            {lists.map((item, index) => (
                                <div key={index}>
                                    <Flexbox alignItems="center">
                                        {item.has ? (<CheckBoxRounded style={{ color: 'var(--primary)' }} />) : (<CloseRounded style={{ color: '#d8d8d8' }} />)}
                                        <Spacebox padding="5px" />
                                        <Typography>
                                            {item.content}
                                        </Typography>
                                    </Flexbox>
                                    <Spacebox padding="5px" />
                                </div>
                            ))}
                        </Spacebox>
                    </div>
                    <div style={{ borderRadius: "20px" }} className="neumorphisim">
                        <Spacebox padding="20px">
                            <Typography style={{ textAlign: 'center' }}>
                                Pro
                            </Typography>
                            <Typography style={{ textAlign: 'center', fontSize: '50px', fontWeight: 700 }}>
                                {currentTab === 0 ? "$40" : "$400"} <small style={{ fontSize: '0.4em', opacity: .7, fontWeight: 300 }}>/{currentTab === 0 ? "monthly" : "annually"}</small>
                            </Typography>
                            <Spacebox padding="10px" />
                            <CustomButton backgroundColor="var(--primary)" color="white" borderRadius="100px" padding="15px 0px" className="fullwidth" handleClick={() => proSub()}>
                                Subscribe
                            </CustomButton>
                            <Spacebox padding="5px" style={{ textAlign: 'center' }}>
                                <small style={{ color: 'red' }}>Endeavor to keep your email consistent when making payment. your email is {user.email}</small>
                            </Spacebox>
                            <Spacebox padding="10px" />
                            {lists.map((item, index) => (
                                <div key={index}>
                                    <Flexbox alignItems="center">
                                        <CheckBoxRounded style={{ color: 'var(--primary)' }} />
                                        <Spacebox padding="5px" />
                                        <Typography>
                                            {item.content.includes("20") ? item.content.replaceAll("20", "Unlimited") : item.content}
                                        </Typography>
                                    </Flexbox>
                                    <Spacebox padding="5px" />
                                </div>
                            ))}
                        </Spacebox>
                    </div>
                </CustomGrid>
                <Spacebox padding="20px" />
            </div>
        </div>
    );
}

export default PricingBoxes;