import { useSelector } from "react-redux";
import OnAuth from "../components/onAuth";
import Spacebox from "../components/styles/Spacebox";
import { Container, Typography } from "@mui/material";
import PricingBoxes from "../components/PricingBoxes";
import { isMobile } from "react-device-detect";

const Plans = ({ title }) => {

    document.querySelector("title").innerHTML = title
    const user = useSelector(state => state.user.value)

    return (
        <OnAuth>
            <Container className="templates-body" style={isMobile ? {paddingLeft: 0, paddingRight: 0} : {}}>
                <Typography variant="h5" className="bold">
                    Plans & Pricing
                </Typography>
                <Typography sx={{ fontSize: '18px' }}>
                    You are currently on {user.plan ?? "no active"} plan, select a prefered plan below. For any questions or inquires, you can reach out to support via live chat.
                </Typography>
                <Spacebox padding="20px" />
                <PricingBoxes />
            </Container>
        </OnAuth>
    );
}

export default Plans;