import { Container, Typography } from "@mui/material";
import OnAuth from "../components/onAuth";
import Toast from "../components/Toast";
import { useState } from "react";
import cookies from "../utilities/Cookies";
import Spacebox from "../components/styles/Spacebox";
import CustomGrid from "../components/styles/Grid";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import Flexbox from "../components/Flexbox";
import { TagOutlined } from "@mui/icons-material";
import { Jelly } from "@uiball/loaders";
import CustomButton from "../components/styles/Custombutton";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const CreateDoc = ({ title }) => {

    document.querySelector("title").innerHTML = title
    const token = cookies.getCookies('token')
    const navigate = useNavigate()

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [name, setName] = useState('Document Name')
    const [googleSheet, setGoogleSheet] = useState('')
    const [documentBody, setDocumentBody] = useState('')
    const [readableBody, setReadableBody] = useState('')

    // React Quill Toolbar Options
    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote'],

        [{ 'list': 'ordered' }, { 'list': 'bullet' }],

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    ];

    const handleDocBody = (e) => {
        setDocumentBody(e)
        let content = e.replaceAll("{{date}}", `${(new Date(Date.now())).toString().substring(0, 16)}`)
            .replaceAll("{{ date }}", `${(new Date(Date.now())).toString().substring(0, 16)}`)
            .replaceAll("{{firstname}}", "<b>User's Firstname Here</b>")
            .replaceAll("{{lastname}}", "<b>User's Lastname Here</b>")
            .replaceAll("{{fullname}}", "<b>User's Fullname Here</b>")
            .replaceAll("{{email}}", "<b>User's Email Here</b>")
            .replaceAll("{{phone}}", "<b>User's Phone Here</b>")
            .replaceAll("{{ firstname }}", "<b>User's Firstname Here</b>")
            .replaceAll("{{ lastname }}", "<b>User's Lastname Here</b>")
            .replaceAll("{{ fullname }}", "<b>User's Fullname Here</b>")
            .replaceAll("{{ email }}", "<b>User's Email Here</b>")
            .replaceAll("{{ phone }}", "<b>User's Phone Here</b>")
        setReadableBody(content)
    }

    const handleSheetId = (e) => {
        let link = e.target.value
        if (link.includes("/edit") && link.includes("https://docs.google.com/spreadsheets/d/")) {
            link = link.replace("https://docs.google.com/spreadsheets/d/", "")
            link = link.split("/edit")[0]
            setGoogleSheet(link)
        } else {
            setToastMsg('Invalid sheets url')
            setOpen(true)
            setSeverity('error')
        }
    }

    const createDocument = (draft) => {
        if (name !== '' && googleSheet !== '' && documentBody.length > 30) {
            setIsLoading(true)
            fetch(process.env.REACT_APP_SERVER + '/create-document?token=' + token, {
                mode: 'cors',
                method: 'POST',
                headers: { "Content-Type": "application/json", "Accept": "application/json", "Origin": "http://localhost:3000" },
                body: JSON.stringify({
                    title: name, body: documentBody, sheets: googleSheet, draft: draft
                })
            }).then(res => res.json())
                .then(res => {
                    if (res.success) {
                        if (draft)
                            setToastMsg("Draft saved")
                        else
                            setToastMsg("Document created successfully")
                        setOpen(true)
                        setSeverity('success')
                        setIsLoading(false)
                        setTimeout(() => {
                            navigate('/dashboard/manage')
                        }, 3000);
                    } else {
                        console.log(res.error)
                        setToastMsg(res.msg)
                        setOpen(true)
                        setSeverity('error')
                        setIsLoading(false)
                    }
                }).catch(err => {
                    console.log("Error message: ", err.message)
                    setToastMsg('An error occured')
                    setOpen(true)
                    setSeverity('error')
                    setIsLoading(false)
                })
        } else {
            setToastMsg('Insufficient information')
            setOpen(true)
            setSeverity('error')
        }
    }


    return (
        <OnAuth>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Container className="create-doc">
                <CustomGrid gap="40px" grid={isMobile ? 1 : 2}>
                    <div className="">
                        <Typography variant="h5" className="bold" style={isMobile ? {fontSize: '25px'} : {}}>Create Document</Typography>
                        <Spacebox padding="5px" />
                        <Spacebox padding="2px">
                            <Typography style={{ fontSize: '18px' }}>
                                Document Name
                            </Typography>
                        </Spacebox>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter document title"
                        />
                        <Spacebox padding="2px">
                            <small style={{ color: 'grey' }}>
                                This also serve as each document's signatory tag name
                            </small>
                        </Spacebox>
                        <Spacebox padding="10px" />
                        <Spacebox padding="2px">
                            <Typography style={{ fontSize: '18px' }}>
                                Google Sheets ID
                            </Typography>
                        </Spacebox>
                        <input
                            type="text"
                            value={googleSheet}
                            onChange={handleSheetId}
                            placeholder="Enter google Sheets id"
                        />
                        <Spacebox padding="2px">
                            <small style={{ color: 'grey' }}>
                                Don't know how to get this ? <a href="https://medium.com/@ujumaduowen/how-to-get-google-sheets-id-google-developer-console-api-key-client-id-for-octakode-script-cc66d395c686" target="_blank" rel="noreferrer">Click here</a>
                            </small>
                        </Spacebox>
                        <Spacebox padding="10px" />
                        <Spacebox padding="2px">
                            <Typography style={{ fontSize: '18px' }}>
                                Document Body
                            </Typography>
                        </Spacebox>
                        <ReactQuill theme="snow" placeholder="Enter Document body here..." modules={{ toolbar: toolbarOptions }} value={documentBody} onChange={handleDocBody} />
                        <Spacebox padding="2px">
                            <small style={{ color: 'grey' }}>
                                {`Variable placeholders should be place inside doble curly braces. Example, to populate the firstname, {{ firstname }}, {{ email }} for email, {{ phone }} for phone number, {{ fullname }} for fullname, {{ date }} for date and {{ lastname }} for last name.`}
                                <br /><b>Note:</b> Variable names are tied to respective input fields on your optin / order form page.
                            </small>
                        </Spacebox>
                        <Spacebox padding="10px" />
                        <CustomGrid gap="20px" grid={isMobile ? 1 : 2}>
                            <CustomButton backgroundColor="var(--secondary)" borderRadius="20px" color="white" handleClick={() => createDocument(true)} padding="15px" className="fullwidth">
                                {isLoading ? (<Flexbox justifyContent="center">
                                    <Jelly size={15} color="white" />
                                </Flexbox>) : "Save As Draft"}
                            </CustomButton>
                            <CustomButton backgroundColor="var(--primary)" borderRadius="20px" color="white" handleClick={() => createDocument(false)} padding="15px" className="fullwidth">
                                {isLoading ? (<Flexbox justifyContent="center">
                                    <Jelly size={15} color="white" />
                                </Flexbox>) : "Publish Contract"}
                            </CustomButton>
                        </CustomGrid>
                        <Spacebox padding="20px" />
                    </div>
                    <div className="hide-on-med-and-down">
                        <Typography variant="h5" className="bold">Preview Document</Typography>
                        <Spacebox padding="5px" />
                        <div className="preview-contract">
                            <Typography>
                                Name: {name}
                            </Typography>
                            <Spacebox padding="5px" />
                            <div style={{ background: "#7BADF92a", borderRadius: '10px', color: '#7BADF9', padding: "10px 20px", display: 'inline-block' }}>
                                <Flexbox alignItems="center">
                                    <TagOutlined sx={{ fontSize: 18 }} />
                                    <Spacebox padding="2px" />
                                    <small>{name}</small>
                                </Flexbox>
                            </div>
                            <Spacebox padding="10px" />
                            <Typography>
                                Document Body :
                            </Typography>
                            <Spacebox padding="2px" />
                            <div className="document-preview-body" dangerouslySetInnerHTML={{ __html: readableBody }}></div>
                        </div>
                        <Spacebox padding="20px" />
                    </div>
                </CustomGrid>
            </Container>
        </OnAuth>
    );
}

export default CreateDoc;