import { Container, Divider, Typography } from "@mui/material";
import OnAuth from "../components/onAuth";
import cookies from "../utilities/Cookies";
import { useSelector } from "react-redux";
import useFetch from "../hooks/useFetch";
import Spacebox from "../components/styles/Spacebox";
import { Jelly } from "@uiball/loaders";
import Flexbox from "../components/Flexbox";
import CustomGrid from "../components/styles/Grid";
import { isMobile } from "react-device-detect";

const PaymentHistory = ({ title }) => {

    document.querySelector("title").innerHTML = title
    const user = useSelector(state => state.user.value)
    const token = cookies.getCookies('token')
    const { data: payments, error, isLoading } = useFetch(process.env.REACT_APP_SERVER + `/get-payment-histories?token=${token}&uid=${user.id}`)

    return (
        <OnAuth>
            <Container className="payment-history-body">
                <Typography variant="h5" className="bold" style={isMobile ? {fontSize: '25px'} : {}}>
                    Billing History
                </Typography>
                <Typography sx={{ fontSize: '18px' }}>
                    Invoices
                </Typography>
                {error && (
                    <div>
                        <Spacebox padding="20px" />
                        <Typography>| An error occured</Typography>
                    </div>
                )}
                {isLoading && (
                    <div>
                        <Spacebox padding="20px" />
                        <Flexbox justifyContent="center">
                            <Jelly size={20} color="var(--primary)" />
                        </Flexbox>
                    </div>
                )}
                {/* show all payments */}
                {payments && (
                    <div style={{ width: isMobile ? '100%' : '80%', margin: 'auto' }}>
                        <Spacebox padding={isMobile ? "20px 0px" : "20px"} />
                        {payments.length > 0 ?
                            <div>
                                {payments.map((payment, index) => (
                                    <div key={index}>
                                        <CustomGrid grid={3} gap="10px">
                                            <Typography style={isMobile ? {fontSize: "18px"} : {}}>
                                                {payment.month}
                                            </Typography>
                                            <Typography sx={{ textAlign: 'center', fontSize: isMobile ? "18px" : "23px" }}>
                                                ${payment.amount}
                                            </Typography>
                                            <Typography style={{ color: payment.successful ? "green" : "red", textAlign: 'right', fontSize: isMobile ? "18px" : "23px" }}>
                                                {payment.successful ? "Paid" : "Failed"}
                                            </Typography>
                                        </CustomGrid>
                                        <Spacebox padding="10px 0px">
                                            <Divider light />
                                        </Spacebox>
                                    </div>
                                ))}
                            </div>
                            : <div>
                                <Typography>
                                    | You have no payment history at this time
                                </Typography>
                            </div>}
                    </div>
                )}
            </Container>
        </OnAuth >
    );
}

export default PaymentHistory;