import { Container, FormControlLabel, FormGroup, IconButton, Switch, Typography } from "@mui/material";
import OnAuth from "../components/onAuth";
import Spacebox from "../components/styles/Spacebox";
import Flexbox from "../components/Flexbox";
import { useEffect, useState } from "react";
import '../css/settings.css'
import '../css/courses.css'
import CustomButton from "../components/styles/Custombutton";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import Toast from "../components/Toast";
import cookies from "../utilities/Cookies";
import { updateuser } from "../features/users";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const Settings = ({ title }) => {

    document.querySelector("title").innerHTML = title

    const token = cookies.getCookies('token')
    const user = useSelector(state => state.user.value)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const navigators = [
        {
            name: 'Account',
        },
        {
            name: isMobile ? 'Contract' : 'Contract Settings',
        },
        {
            name: isMobile ? 'Subscr...' : 'Subscription',
        },
    ]

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');
    const [loading, setLoading] = useState(false);

    const [currentTab, setCurrentTab] = useState(0)

    const [openModal, setOpenModal] = useState(false)
    const [openProfile, setOpenProfile] = useState(false)
    const [openPassword, setOpenPassword] = useState(false)
    const [openContract, setOpenContract] = useState(false)

    const [email, setEmail] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')

    const [companyName, setCompanyName] = useState('')
    const [companyAddress, setCompanyAddress] = useState('')
    const [companyState, setCompanyState] = useState('')
    const [companyZipCode, setCompanyZipCode] = useState('')
    const [companyCountry, setCompanyCountry] = useState('')
    const [brandingPosition, setBrandingPosition] = useState('top right')
    const [emailNotification, setEmailNotification] = useState(null)

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const initialData = (val) => {
        if (val) {
            setEmail(val.email)
            setFirstname(val.firstname)
            setLastname(val.lastname)
            setEmailNotification(val.email_notification)
            if (val.company_name) {
                setCompanyName(val.company_name)
                setCompanyAddress(val.company_address)
                setCompanyState(val.company_state)
                setCompanyZipCode(val.company_zipcode)
                setCompanyCountry(val.company_country)
                setBrandingPosition(val.branding_position)
            }
        } else {
            setEmail(user.email)
            setFirstname(user.firstname)
            setLastname(user.lastname)
            setEmailNotification(user.email_notification)
            if (user.company_name) {
                setCompanyName(user.company_name)
                setCompanyAddress(user.company_address)
                setCompanyState(user.company_state)
                setCompanyZipCode(user.company_zipcode)
                setCompanyCountry(user.company_country)
                setBrandingPosition(user.branding_position)
            }
        }
    }


    const updateEmailNotification = (bool) => {
        setLoading(true)
        setEmailNotification(bool)
        fetch(process.env.REACT_APP_SERVER + `/update-user-details?token=${token}`, {
            mode: 'cors',
            method: 'POST',
            headers: { "Content-Type": "application/json", "Accept": "application/json", "Origin": "http://localhost:3000" },
            body: JSON.stringify({
                email_notification: bool ? true : false
            })
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    setToastMsg("Details updated successfully")
                    setOpen(true)
                    setSeverity('success')
                    setLoading(false)
                    setOpenModal(false)
                    // update user details
                    reloadUserData()
                } else {
                    console.log(res.error)
                    setToastMsg(res.msg)
                    setOpen(true)
                    setSeverity('error')
                    setLoading(false)
                }
            }).catch(err => {
                console.log("Error message: ", err.message)
                setToastMsg('An error occured')
                setOpen(true)
                setSeverity('error')
                setLoading(false)
            })
    }


    const updateBrand = () => {
        // this will be added to all documents
        if (companyAddress !== '' && companyName !== '' && companyState !== '' & companyCountry !== '' & companyZipCode !== '') {
            setLoading(true)
            fetch(process.env.REACT_APP_SERVER + `/update-user-docs-details?token=${token}`, {
                mode: 'cors',
                method: 'POST',
                headers: { "Content-Type": "application/json", "Accept": "application/json", "Origin": "http://localhost:3000" },
                body: JSON.stringify({
                    company_address: companyAddress,
                    company_name: companyName,
                    company_state: companyState,
                    company_zipcode: companyZipCode,
                    company_country: companyCountry,
                    branding_position: brandingPosition
                })
            }).then(res => res.json())
                .then(res => {
                    if (res.success) {
                        setToastMsg("Details updated successfully")
                        setOpen(true)
                        setSeverity('success')
                        setLoading(false)
                        setOpenModal(false)
                        // update user details
                        reloadUserData()
                    } else {
                        console.log(res.error)
                        setToastMsg(res.msg)
                        setOpen(true)
                        setSeverity('error')
                        setLoading(false)
                    }
                }).catch(err => {
                    console.log("Error message: ", err.message)
                    setToastMsg('An error occured')
                    setOpen(true)
                    setSeverity('error')
                    setLoading(false)
                })
        } else {
            setSeverity('error');
            setOpen(true)
            setToastMsg("Incomplete brand information")
        }
    }

    useEffect(() => {
        initialData()
        // eslint-disable-next-line
    }, [])

    const updateProfile = () => {
        if (firstname !== '' && lastname !== '') {
            setLoading(true)
            fetch(process.env.REACT_APP_SERVER + `/update-user-details?token=${token}`, {
                mode: 'cors',
                method: 'POST',
                headers: { "Content-Type": "application/json", "Accept": "application/json", "Origin": "http://localhost:3000" },
                body: JSON.stringify({
                    firstname, lastname
                })
            }).then(res => res.json())
                .then(res => {
                    if (res.success) {
                        setToastMsg("Account details updated successfully")
                        setOpen(true)
                        setSeverity('success')
                        setLoading(false)
                        setOpenModal(false)
                        // update user details
                        reloadUserData()
                    } else {
                        console.log(res.error)
                        setToastMsg(res.msg)
                        setOpen(true)
                        setSeverity('error')
                        setLoading(false)
                    }
                }).catch(err => {
                    console.log("Error message: ", err.message)
                    setToastMsg('An error occured')
                    setOpen(true)
                    setSeverity('error')
                    setLoading(false)
                })
        } else {
            setSeverity('error');
            setOpen(true)
            setToastMsg("Incomplete brand information")
        }
    }

    const changePassword = () => {
        if (password.length > 7 && password === confirmPassword) {
            setLoading(true)
            fetch(process.env.REACT_APP_SERVER + '/reset', {
                mode: 'cors',
                method: 'POST',
                headers: { "Content-Type": "application/json", "Accept": "application/json", "Origin": "http://localhost:3000" },
                body: JSON.stringify({ password, token })
            }).then(res => res.json())
                .then(res => {
                    if (res.success) {
                        setToastMsg("password updated successfully")
                        setOpen(true)
                        setSeverity('success')
                        setLoading(false)
                        setOpenModal(false)
                        // update user details
                        reloadUserData()
                    } else {
                        console.log(res.error)
                        setToastMsg(res.msg)
                        setOpen(true)
                        setSeverity('error')
                        setLoading(false)
                    }
                }).catch(err => {
                    console.log("Error message: ", err.message)
                    setToastMsg('An error occured')
                    setOpen(true)
                    setSeverity('error')
                    setLoading(false)
                })
        } else {
            setSeverity('error');
            setOpen(true)
            setToastMsg(password.length > 7 ? "Password mismatch" : "Password is too short")
        }
    }

    const openmodal = (text) => {
        try {
            if (text === 'profile')
                setOpenProfile(true)
            else if (text === 'password')
                setOpenPassword(true)
            else if (text === 'contract')
                setOpenContract(true)
            else
                throw Error('Undefined command')

            setOpenModal(true)
            setTimeout(() => {
                document.querySelector('.course-sidebar').classList.add('slow-show')
            }, 500);
        } catch (err) {
            setSeverity('error');
            setOpen(true)
            setToastMsg(err.message)
        }
    }

    const closemodal = (close) => {
        document.querySelector('.course-sidebar').classList.remove('slow-show')
        setTimeout(() => {
            setOpenModal(false)
            close(false)
        }, 500);
    }

    function reloadUserData() {
        fetch(process.env.REACT_APP_SERVER + `/user?uid=${user.id}`)
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    cookies.setCookies("user", JSON.stringify(res.user), 0.5)
                    initialData(res.user)
                    dispatch(updateuser(res.user))
                }
            }).catch(err => {
                console.log("Error message: ", err.message)
            })
    }
    return (
        <OnAuth>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Container className="settings-page" style={isMobile ? { paddingLeft: 0, paddingRight: 0 } : {}}>
                <Typography className="bold" variant="h5">
                    Account Settings
                </Typography>
                <Spacebox padding="10px" />
                <div className="navigators">
                    <Flexbox alignItems="center" >
                        {navigators.map((nav, index) => (
                            <div style={{ backgroundColor: index === currentTab && 'white', boxShadow: index === currentTab && 'rgba(86, 97, 107, 0.1) 0px 1px 2px 0px' }} className="cust-btn" key={index} onClick={() => setCurrentTab(index)}>
                                <span style={{ color: index === currentTab ? '#131316' : '#56616B' }}>{nav.name}</span>
                            </div>
                        ))}
                    </Flexbox>
                </div>
                <Spacebox padding="20px" />
                {currentTab === 0 && <div className="account-tab">
                    <Flexbox alignItems="center" justifyContent="space-between">
                        <div>
                            <p style={{ fontSize: '20px' }} className="bold margin0">Profile</p>
                            <small style={{ color: 'grey', fontSize: '15px' }}>Edit your profile details</small>
                        </div>
                        <CustomButton backgroundColor="white" color="black" borderRadius="100px" padding={isMobile ? "15px 30px" : "15px 50px"} style={{ width: isMobile ? "fit-content" : '250px', border: '1px solid black' }} handleClick={() => openmodal('profile')}>
                            Edit Profile
                        </CustomButton>
                    </Flexbox>
                    <Spacebox padding="20px" />
                    <Flexbox alignItems="center" justifyContent="space-between">
                        <div>
                            <p style={{ fontSize: '20px' }} className="bold margin0">Change Password</p>
                            <small style={{ color: 'grey', fontSize: '15px' }}>Change your old password to a new one.</small>
                        </div>
                        <CustomButton backgroundColor="white" color="black" borderRadius="100px" padding={isMobile ? "15px 30px" : "15px 50px"} style={{ width: isMobile ? "fit-content" : '250px', border: '1px solid black' }} handleClick={() => openmodal('password')}>
                            {isMobile ? "Change" : "Change Password"}
                        </CustomButton>
                    </Flexbox>
                </div>}
                {currentTab === 1 && <div className="contract-tab">
                    <Flexbox alignItems="center" justifyContent="space-between">
                        <div>
                            <p style={{ fontSize: '20px' }} className="bold margin0">Notify me when a contract is signed</p>
                            <Spacebox padding="2px" />
                            <Flexbox alignItems="center">
                                <FormGroup>
                                    <FormControlLabel control={<Switch value={emailNotification} defaultChecked={emailNotification} onChange={(e) => updateEmailNotification(e.target.checked)} inputProps={{ 'aria-label': 'controlled' }} />} color="primary" />
                                </FormGroup>
                            </Flexbox>
                            <small style={{ color: '#b8b8b8' }}>
                                You will get an email notification for every active contract your customers signs when you enable this option.
                            </small>
                        </div>
                        <div></div>
                    </Flexbox>
                    <Spacebox padding="20px" />
                    <Flexbox alignItems="center" justifyContent="space-between">
                        <div>
                            <p style={{ fontSize: '20px' }} className="bold margin0">Branding</p>
                            <small style={{ color: 'grey', fontSize: '15px' }}>Set up your your company's branding accross all your contracts</small>
                        </div>
                        <CustomButton backgroundColor="black" color="white" borderRadius="100px" padding={isMobile ? "15px 30px" : "15px 50px"} style={{ width: '250px' }} handleClick={() => openmodal('contract')}>
                            Setup Branding
                        </CustomButton>
                    </Flexbox>


                </div>}
                {currentTab === 2 && <div className="subscription-tab">
                    <Flexbox alignItems="center" justifyContent="space-between">
                        <div>
                            <p style={{ fontSize: '20px' }} className="bold margin0">Change Plan</p>
                            <small style={{ color: 'grey', fontSize: '15px' }}>You are currently on {user.trial ? 'Trial' : user.plan ? (user.plan).toLowerCase() : ''} plan</small>
                        </div>
                        <CustomButton backgroundColor="white" color="black" borderRadius="100px" padding={isMobile ? "15px 30px" : "15px 50px"} style={{ width: isMobile ? "fit-content" : '250px', border: '1px solid black' }} handleClick={() => navigate("/dashboard/plans")}>
                            {isMobile ? "Change" : "Change Plan"}
                        </CustomButton>
                    </Flexbox>
                </div>}
            </Container>
            {(openModal && user) &&
                <div className="new-course-bg"
                    style={{ top: 0, height: '100vh' }}
                >
                    {openProfile && <div className="course-sidebar">
                        <Flexbox alignItems="center" justifyContent="space-between">
                            <Typography className="bold">
                                Edit profile
                            </Typography>
                            <IconButton onClick={() => closemodal(setOpenProfile)}>
                                <Close style={{ color: 'black' }} />
                            </IconButton>
                        </Flexbox>
                        <Spacebox padding="10px" />
                        <div className="inner-scroll">
                            <input
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                                placeholder="Firstname"
                            />
                            <Spacebox padding="10px" />
                            <input
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                                placeholder="Lastname"
                            />
                            <Spacebox padding="10px" />
                            <input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                                disabled
                            />
                            <Spacebox padding="10px" />
                            <CustomButton backgroundColor="var(--primary)" color="white" borderRadius="100px" padding="15px 30px" className="fullwidth" handleClick={() => updateProfile()}>
                                {loading ? `Updating Profile...` : 'Update Profile'}
                            </CustomButton>
                            <Spacebox padding="20px" />
                            <Spacebox padding="20px" />
                        </div>
                    </div>}
                    {openPassword && <div className="course-sidebar">
                        <Flexbox alignItems="center" justifyContent="space-between">
                            <Typography className="bold">
                                Change password
                            </Typography>
                            <IconButton onClick={() => closemodal(setOpenPassword)}>
                                <Close style={{ color: 'black' }} />
                            </IconButton>
                        </Flexbox>
                        <Spacebox padding="10px" />
                        <div className="inner-scroll">
                            <input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                                type="password"
                            />
                            <Spacebox padding="2px 5px">
                                <small style={{ opacity: .6 }}>
                                    Password should be over 7 characters long
                                </small>
                            </Spacebox>
                            <Spacebox padding="10px" />
                            <input
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm Password"
                                type="password"
                            />
                            <Spacebox padding="10px" />
                            <CustomButton backgroundColor="var(--primary)" color="white" borderRadius="100px" padding="15px 30px" className="fullwidth" handleClick={() => changePassword()}>
                                {loading ? `Changing password..` : 'Change password'}
                            </CustomButton>
                            <Spacebox padding="20px" />
                            <Spacebox padding="20px" />
                        </div>
                    </div>}
                    {openContract && <div className="course-sidebar">
                        <Flexbox alignItems="center" justifyContent="space-between">
                            <Typography className="bold">
                                Edit Brand
                            </Typography>
                            <IconButton onClick={() => closemodal(setOpenContract)}>
                                <Close style={{ color: 'black' }} />
                            </IconButton>
                        </Flexbox>
                        <Spacebox padding="10px" />
                        <div className="inner-scroll">
                            <input
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                placeholder="Company Name"
                            />
                            <Spacebox padding="10px" />
                            <input
                                value={companyAddress}
                                onChange={(e) => setCompanyAddress(e.target.value)}
                                placeholder="Address"
                            />
                            <Spacebox padding="10px" />
                            <input
                                value={companyState}
                                onChange={(e) => setCompanyState(e.target.value)}
                                placeholder="State"
                            />
                            <Spacebox padding="10px" />
                            <input
                                value={companyZipCode}
                                onChange={(e) => setCompanyZipCode(e.target.value)}
                                placeholder="Zip Code"
                            />
                            <Spacebox padding="10px" />
                            <input
                                value={companyCountry}
                                onChange={(e) => setCompanyCountry(e.target.value)}
                                placeholder="Country"
                            />
                            <Spacebox padding="10px" />
                            <select
                                value={brandingPosition}
                                onChange={(e) => setBrandingPosition(e.target.value)}
                            >
                                <option value="top right">Top Right</option>
                                <option value="top left">Top Left</option>
                                <option value="bottom right">Bottom Right</option>
                                <option value="bottom left">Bottom Left</option>
                            </select>
                            <Spacebox padding="10px" />
                            <CustomButton backgroundColor="var(--primary)" color="white" borderRadius="100px" padding="15px 30px" className="fullwidth" handleClick={() => updateBrand()}>
                                {loading ? `Updating Brand...` : 'Update Brand'}
                            </CustomButton>
                            <Spacebox padding="20px" />
                            <Spacebox padding="20px" />
                        </div>
                    </div>}
                </div>}
        </OnAuth>
    );
}

export default Settings;