import { Typography } from "@mui/material";
import CustomGrid from "../components/styles/Grid";
import Testimonies from "../components/Testimonies";
import Flexbox from "../components/Flexbox";
import CustomButton from "../components/styles/Custombutton";
import Spacebox from "../components/styles/Spacebox";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import '../css/auth.css'
import { Link, useNavigate } from "react-router-dom";
import Toast from "../components/Toast";
import cookies from "../utilities/Cookies";
import { updateuser } from "../features/users";

const Login = ({title}) => {

    document.querySelector("title").innerHTML = title
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')


    const [loading, setLoading] = useState(false)

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');



    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleLogin = () => {
        setLoading(true)
        if (password.length > 7 && email.includes('@')) {
            fetch( process.env.REACT_APP_SERVER + '/login?api_token' + process.env.REACT_APP_API_TOKEN, {
                mode: 'cors',
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Origin': 'https://localhost:3000' },
                body: JSON.stringify({ email, password })
            }).then(res => res.json())
                .then(res => {
                    if (res.success) {
                        // save token
                        cookies.setCookies('token', res.token, 0.5)
                        cookies.setCookies('user', JSON.stringify(res.user), 0.5)
                        dispatch(updateuser(res.user))
                        setToastMsg('Login successful')
                        setOpen(true)
                        setSeverity('success')
                        setLoading(false)
                        determineUserRoute(res.user)
                    } else {
                        console.log(res.error)
                        setToastMsg(res.msg)
                        setOpen(true)
                        setSeverity('error')
                        setLoading(false)
                    }
                }).catch(err => {
                    console.log("Error message: ", err.message)
                    setToastMsg('An error occured, Try again')
                    setOpen(true)
                    setSeverity('error')
                    setLoading(false)
                })
        } else {
            setToastMsg('Invalid input')
            setOpen(true)
            setSeverity('error')
            setLoading(false)
        }

    }

    function determineUserRoute(user) {
        switch(user.status){
            case "paid":
                setTimeout(() => {
                    navigate('/dashboard')
                }, 3000);
                break;
            case "pending":
                setTimeout(() => {
                    navigate('/dashboard/pending')
                }, 3000);
                break;
            default:
                setTimeout(() => {
                    navigate('/dashboard/pending')
                }, 3000);
                break;
        }
    }

    useEffect(() => {
        let token = cookies.getCookies('token')
        // 10 is a random number 
        if(token.length > 10) {
            navigate('/dashboard')
        }
        //eslint-disable-next-line
    }, [])


    return (
        <div className="login-page">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <CustomGrid gap="20px" grid="2" className="remove-grid-med-nd-down">
                <Flexbox alignItems="center" justifyContent="center" className="full gradient-animate hide-on-med-and-down">
                    <div className="margin-auto glass" style={{ width: '80%', color: 'white', padding: '20px' }}>
                        <Testimonies />
                    </div>
                </Flexbox>
                <Spacebox padding="5px" className="gradient-animate hide-on-large-only" />
                <div style={{ padding: '20px' }}>
                    <Flexbox justifyContent="space-between" alignItems="center">
                        <div className="logo-icon">
                            <img src="/assets/logo_icon.png" alt="docly icon" />
                        </div>
                        <CustomButton backgroundColor="#f0f0f0" color="black" borderRadius="50px" padding="15px 30px" handleClick={() => navigate("/signup")}>
                            {"Not a member? sign up now"}
                        </CustomButton>
                    </Flexbox>
                    <Spacebox padding="20px" />
                    <Typography variant="h4" className="bold">
                        Log In
                    </Typography>
                    <Spacebox padding="2px" />
                    <Typography variant="body2" sx={{ color: '#ababab' }}>
                        Welcome back, enter your details to login to your memblify account.
                    </Typography>
                    <Spacebox padding="20px" />
                    <div className="mid">
                        <small>Email</small>
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="fullwidth"
                            type="email"
                        />
                        <Spacebox padding="10px" />
                        <small>Password</small>
                        <input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="fullwidth"
                            type="password"
                        />
                        <Spacebox padding="10px" />
                        <Link to="/forgot-password" style={{ color: 'var(--secondary)' }}>Forgotten Password</Link>
                        <Spacebox padding="10px" />
                        <CustomButton backgroundColor="var(--primary)" color="white" borderRadius="50px" padding="15px 0px" className="fullwidth" handleClick={handleLogin}>
                            {loading ? "Authenticating..." : "Log In"}
                        </CustomButton>
                    </div>
                </div>
            </CustomGrid>
        </div>
    );
}

export default Login;