import { Container, Typography } from "@mui/material";
// import Toast from "../components/Toast";
// import { useSelector } from "react-redux";
// import cookies from "../utilities/Cookies";
// import { useState } from "react";
import Spacebox from "../components/styles/Spacebox";
import OnAuth from "../components/onAuth";
import PricingBoxes from "../components/PricingBoxes";
import { isMobile } from "react-device-detect";

const Pending = ({ title }) => {

    document.querySelector("title").innerHTML = title

    return (
        <OnAuth>
            <Container className="pending-body"  style={isMobile ? {paddingLeft: 0, paddingRight: 0} : {}}>
                <Typography className="bold" variant="h5">Account on hold</Typography>
                <Spacebox padding="10px" />
                <Typography style={{ fontSize: '18px' }}>
                    Your account is on hold and requires a few actions to be restored
                </Typography>
                <Spacebox padding="20px" />
                <PricingBoxes />
            </Container>
        </OnAuth>
    );
}

export default Pending;