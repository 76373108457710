import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../components/Toast";
import CustomGrid from "../components/styles/Grid";
import Flexbox from "../components/Flexbox";
import Testimonies from "../components/Testimonies";
import Spacebox from "../components/styles/Spacebox";
import { Typography } from "@mui/material";
import CustomButton from "../components/styles/Custombutton";

const Reset = ({title}) => {

    document.querySelector("title").innerHTML = title
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [loading, setLoading] = useState(false)

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');



    const navigate = useNavigate()

    const handleReset = () => {
        setLoading(true)
        if (password.length > 7 && password === confirmPassword) {
            fetch(process.env.REACT_APP_SERVER + '/reset', {
                mode: 'cors',
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Origin': 'https://localhost:3000' },
                body: JSON.stringify({ password, token })
            }).then(res => res.json())
                .then(res => {
                    if (res.success) {
                        setToastMsg('Password updated successfully')
                        setOpen(true)
                        setSeverity('success')
                        setLoading(false)
                        setTimeout(() => {
                            navigate('/')
                        }, 3000);
                    } else {
                        console.log(res.error)
                        setToastMsg(res.msg)
                        setOpen(true)
                        setSeverity('error')
                        setLoading(false)
                    }
                }).catch(err => {
                    console.log("Error message: ", err.message)
                    setToastMsg('An error occured, Try again')
                    setOpen(true)
                    setSeverity('error')
                    setLoading(false)
                })
        } else {
            setToastMsg('Invalid input')
            setOpen(true)
            setSeverity('error')
            setLoading(false)
        }

    }

    return (
        <div className="forgotten-password">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <CustomGrid gap="20px" grid="2" className="remove-grid-med-nd-down">
                <Flexbox alignItems="center" justifyContent="center" className="full gradient-animate hide-on-med-and-down">
                    <div className="margin-auto glass" style={{ width: '80%', color: 'white', padding: '20px' }}>
                        <Testimonies />
                    </div>
                </Flexbox>
                <Spacebox padding="5px" className="gradient-animate hide-on-large-only" />
                <div style={{ padding: '20px' }}>
                    <Flexbox justifyContent="space-between" alignItems="center">
                        <div className="logo-icon">
                            <img src="/assets/logo_icon.png" alt="docly icon" />
                        </div>
                        <CustomButton backgroundColor="#f0f0f0" color="black" borderRadius="50px" padding="15px 30px" handleClick={() => navigate("/")}>
                            {"Go back to login page"}
                        </CustomButton>
                    </Flexbox>
                    <Spacebox padding="20px" />
                    <Typography variant="h4" className="bold hide-on-med-and-down">
                        Resset Password
                    </Typography>
                    <Spacebox padding="2px" />
                    <Typography variant="body2" sx={{ color: '#ababab' }}>
                        Enter new password
                    </Typography>
                    <Spacebox padding="20px" />
                    <div className="mid">
                        <small>Password</small>
                        <input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="fullwidth"
                            type="password"
                        />
                        <Spacebox padding="10px" />
                        <small>Confirm Password</small>
                        <input
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="fullwidth"
                            type="password"
                        />
                        <Spacebox padding="10px" />
                        <CustomButton backgroundColor="var(--primary)" color="white" borderRadius="50px" padding="15px 0px" className="fullwidth" handleClick={handleReset}>
                            {loading ? "Authenticating..." : "Reset Password"}
                        </CustomButton>
                    </div>
                </div>
            </CustomGrid>
        </div>
    );
}

export default Reset;