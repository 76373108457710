import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";
import Spacebox from "./styles/Spacebox";
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from "react";
import { updateuser } from "../features/users";
import Flexbox from "./Flexbox";
import { Typography } from "@mui/material";
import Toast from "./Toast";
import cookies from "../utilities/Cookies";
import { Jelly } from '@uiball/loaders';

const Layout = () => {
    
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false)

    const height = useSelector(state => state.headerHeight.value)
    const navigate = useNavigate()
    
    
    useEffect(() => {
        const stringify_user = cookies.getCookies('user');
        const token = cookies.getCookies('token');
        if (token === '' || token === undefined || token === null) {
            navigate('/');
        }
        const user = JSON.parse(stringify_user)

        fetch(process.env.REACT_APP_SERVER + '/user?&uid=' + user.id)
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    dispatch(updateuser(res.user))
                    setLoading(false)
                    setSuccess(true)
                    setError(false)
                } else {
                    if (res.error === "Unauthorised request")
                        navigate('/')

                    console.log(res.error)
                    setToastMsg(res.msg)
                    setOpen(true)
                    setSeverity('error')
                    setLoading(false)
                    setError(true)
                }
            }).catch(err => {
                console.log("Error message: ", err.message)
                setToastMsg('An error occured, Try again')
                setOpen(true)
                setSeverity('error')
                setLoading(false)
                setError(true)
            })
        //eslint-disable-next-line
    }, [])

    return (
        <div>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            {loading && <Flexbox justifyContent="center" alignItems="center" className="full">
                <div className="small" style={{ textAlign: "center" }}>
                    <Flexbox justifyContent="center"><Jelly size={80} speed={0.9}  color="black"  /></Flexbox>
                </div>
            </Flexbox>}
            {success && <div className="layout">
                <Header />
                {/* Allow space for the header navbar on laptop */}
                <Spacebox padding="0px" className="hide-on-med-and-down" style={{ height: height.laptop }} />
                <Spacebox padding="20px">
                    <Outlet />
                    {/* Allow space for the header navbar on mobile */}
                    <Spacebox padding="0px" className="hide-on-large-only" style={{ height: height.mobile }} />
                </Spacebox>
            </div>}
            {error && <Flexbox justifyContent="center" alignItems="center" className="full">
                <div className="mid" style={{ textAlign: "center" }}>
                    <img src="/svgs/notFound.svg" alt="docly" style={{ width: '200px' }} />
                    <Typography variant="h6" className="bold"> There seems to be a problem </Typography>
                </div>
            </Flexbox>}
        </div>
    );
}

export default Layout;