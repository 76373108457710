import {createSlice} from '@reduxjs/toolkit'

export const headerHeightSlice = createSlice({
    name: 'headerHeight',
    initialState: {value: {laptop: 0, mobile: 0}},
    reducers: {
        updateHeaderHeight: (state, action) => {
            state.value = action.payload
        }
    }
})

export const {updateHeaderHeight} = headerHeightSlice.actions

export default headerHeightSlice.reducer