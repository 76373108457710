import { createTheme } from '@mui/material'
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ForgottenPassword from './pages/ForgottenPassword';
import Reset from './pages/reset';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import AuthcallBack from './pages/Authcallback';
import CreateDoc from './pages/Create';
import Manage from './pages/Manage';
import Document from './pages/Document';
import NotFound from './pages/NotFound';
import Templates from './pages/Templates';
import Pending from './pages/Pending';
import Tutorials from './pages/Tutorials';
import Settings from './pages/Settings';
import Plans from './pages/Plans';
import PaymentHistory from './pages/PaymentHistory';
import Sign from './pages/Sign';

function App() {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#000'
      },
      secondary: {
        main: '#7badf9'
      }
    },

    typography: {
      fontFamily: "Fira Sans Extra Condensed",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightBold: 800,
      fontSize: 20
    }
  })


  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path='/' element={<Login title="Login | Octakode Docly"/>} />
          <Route exact path='/login' element={<Login title="Login | Octakode Docly"/>} />
          <Route exact path="/signup" element={<Signup title="Sign Up | Octakode Docly"/>} />
          <Route exact path="/forgot-password" element={<ForgottenPassword title="Forgot Password | Octakode Docly"/>} />
          <Route exact path="/reset" element={<Reset title="Reset Password | Octakode Docly"/>} />
          <Route exact path="/dashboard" element={<Layout />} >
            <Route exact path="" element={<Dashboard title="Dashboard | Octakode Docly"/>} />
            <Route exact path="create" element={<CreateDoc title="Create Document | Octakode Docly"/>} />
            <Route exact path="manage" element={<Manage title="Manage Document | Octakode Docly"/>} />
            <Route exact path="manage/:id" element={<Document title="Manage Document | Octakode Docly"/>} />
            <Route exact path="templates" element={<Templates title="Template Contracts | Octakode Docly"/>} />
            <Route exact path="tutorials" element={<Tutorials title="Tutorials | Octakode Docly"/>} />
            <Route exact path="pending" element={<Pending title="Account Pending | Octakode Docly"/>} />
            <Route exact path="settings" element={<Settings title="Account Settings | Octakode Docly"/>} />
            <Route exact path="payments" element={<PaymentHistory title="Payment History | Octakode Docly"/>} />
            <Route exact path="plans" element={<Plans title="Change Plan | Octakode Docly"/>} />
            <Route exact path="oauth2callback" element={<AuthcallBack title="Authenticating Google | Octakode Docly"/>} />
          </Route>
          <Route exact path='/sign/:uid/:id' element={<Sign title="Sign Documnet | Octakode Docly"/>} />
          <Route exact path="*" element={<NotFound />}/>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
