import { Jelly } from '@uiball/loaders';
import { Avatar, Divider, Typography } from "@mui/material";
import Spacebox from "./styles/Spacebox";
import { useEffect, useState } from "react";
import Flexbox from "./Flexbox";
import { LocationOnOutlined } from "@mui/icons-material";

const Testimonies = () => {

    const testimonies = [
        {
            testimony: `I've been using the contract part of octakode script for over 5 months now and my revenue has spiked since then. I no longer worry about refunds and chargebacks. How a simple piece of software has so much impact on revenue is nothing short of amazing. Highly recommend.`,
            image: '/people/anna_dubravka.jpg',
            author: 'Anna Dubravka',
            country: 'US'
        },
        {
            testimony: `I decided to get into the online space and have been having trouble getting my funnels to look good. I came across octakode script's ad on facebook and the rest is history. I found their plugins very easy to use and integrate on my funnels. I probably have their effects on all my funnels at this point lol.`,
            image: '/people/hannah_nguyen.jpg',
            author: 'Hannah Nguyen',
            country: 'AU'
        },
        {
            testimony: `Its amazing that octakode has the ability to change ones' business in a heartbeat. Their support is also second to none, by far the best software company I have come in contact with.`,
            image: '/people/michael_ezenwa.jpg',
            author: 'Michael Ezenwa',
            country: 'UK'
        }
    ]

    const [index, setIndex] = useState(null)
    const [loading, setLoading] = useState(true)

    const randomNum = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {
        setIndex(randomNum(0, testimonies.length - 1))
        setLoading(false)
        // eslint-disable-next-line
    }, [])

    return (
        <div className="testimony-div">
            {loading && <Flexbox justifyContent="center"><Jelly size={80} speed={0.9}  color="black"  /></Flexbox>}
            {!loading && <div className="testimonial">
                <Typography variant="body1">
                    {testimonies[index].testimony}
                </Typography>
                <Spacebox padding="10px" />
                <Flexbox alignItems="center">
                    <Avatar alt="memblify" src={testimonies[index].image} />
                    <Spacebox padding="5px" />
                    <span>{testimonies[index].author} </span>
                </Flexbox>
                <Spacebox padding="10px"/>
                <Flexbox alignItems="flex-end" justifyContent="flex-end">
                    <LocationOnOutlined style={{ fontSize: '18px'}} />
                    <Spacebox padding="2px">
                        <Divider />
                    </Spacebox>
                    <small>{testimonies[index].country}</small>
                </Flexbox>
            </div>}
        </div>
    );
}

export default Testimonies;