import { Container, IconButton, Typography } from "@mui/material";
import OnAuth from "../components/onAuth";
import Toast from "../components/Toast";
import cookies from "../utilities/Cookies";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Flexbox from "../components/Flexbox";
import { Jelly, Ping } from "@uiball/loaders";
import CustomGrid from "../components/styles/Grid";
import { ChevronRight, ContentCopyOutlined, DateRangeOutlined, TagOutlined } from "@mui/icons-material";
import Spacebox from "../components/styles/Spacebox";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import ArrowPagination from "../components/ArrowPagination";
import String from "../utilities/Strings";

const Manage = ({ title }) => {

    document.querySelector("title").innerHTML = title
    const user = useSelector(state => state.user.value)
    const token = cookies.getCookies('token')
    const navigate = useNavigate()


    const [documents, setDocuments] = useState(null)
    const [paginationDocuments, setPaginationDocuments] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');


    const loadDocuments = () => {
        setLoading(true)
        fetch(process.env.REACT_APP_SERVER + `/get-documents?uid=${user.id}&token=${token}`)
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    setPaginationDocuments(res.data)
                    setLoading(false)
                } else {
                    console.log(res.error)
                    setError(res.msg)
                    setLoading(false)
                }
            }).catch(err => {
                console.log("Error message: ", err.message)
                setError('An error occured')
                setLoading(false)
            })
    }

    const duplicateDoc = (doc) => {
        if (window.confirm("You are about to duplicate " + doc.title)) {
            setLoading(true)
            fetch(process.env.REACT_APP_SERVER + `/duplicate-document?uid=${user.id}&token=${token}&did=${doc.id}`)
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        setToastMsg(`${doc.title} duplicated successfully`)
                        setOpen(true)
                        setSeverity('success')
                        setLoading(false)
                        loadDocuments()
                    } else {
                        console.log(res.error)
                        setToastMsg(res.msg)
                        setOpen(true)
                        setSeverity('error')
                        setLoading(false)
                    }
                }).catch(err => {
                    console.log("Error message: ", err.message)
                    setToastMsg('An error occured')
                    setOpen(true)
                    setSeverity('error')
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        loadDocuments()

        // eslint-disable-next-line
    }, [])
    return (
        <OnAuth>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Container className="manage-doc" style={isMobile ? { padding: 0 } : {}}>
                <Typography variant="h5" className="bold" style={isMobile ? { fontSize: '25px' } : {}}>Existing Documents</Typography>
                <Typography variant="subtitle2" style={{ color: 'grey', fontSize: '16px' }}>
                    Below are all your available contracts
                </Typography>
                <Spacebox padding="20px" />
                {error && (<Typography>An error occured</Typography>)}
                {loading && (
                    <Flexbox justifyContent="center">
                        <Jelly size={20} color="var(--primary)" />
                    </Flexbox>
                )}
                {paginationDocuments && (
                    <div>
                        {!isMobile && (
                            <div>
                                {documents && <CustomGrid gap={isMobile ? "10px" : "20px"} grid={4}>
                                    {documents.length > 0 ? documents.map((doc, index) => (
                                        <div key={index} style={{ padding: '10px', borderRadius: '10px', background: '#EDEDED' }}>
                                            <Flexbox justifyContent="space-between" alignItems="center">
                                                <div style={{ background: `${doc.active ? "#56b7652a" : "#8080802a"} `, borderRadius: '10px', color: `${doc.active ? "#56b765" : "grey"} `, padding: "5px 10px", display: 'inline-block' }}>
                                                    <Flexbox alignItems="center">
                                                        <Ping size={15} color={doc.active ? "#56b765" : "grey"} />
                                                        <Spacebox padding="2px" />
                                                        <small style={{ fontSize: '13px' }}>{doc.active ? "Active" : "Inactive"}</small>
                                                    </Flexbox>
                                                </div>
                                                <IconButton onClick={() => duplicateDoc(doc)}>
                                                    <ContentCopyOutlined sx={{ fontSize: 18 }} />
                                                </IconButton>
                                            </Flexbox>
                                            <Spacebox padding="10px" />
                                            <Flexbox justifyContent="center">
                                                <img src="/assets/docs.png" alt="octakode docly" style={{ width: '100px' }} />
                                            </Flexbox>
                                            <Spacebox padding="10px" />
                                            <Typography noWrap sx={{ fontSize: '23px' }} className="bold">
                                                {String.shorten(doc.title, 15)}
                                            </Typography>
                                            <Spacebox padding="5px" />
                                            <Flexbox alignItems="center" style={{ flexWrap: 'wrap' }}>
                                                <div style={{ background: "#7BADF92a", borderRadius: '10px', color: '#7BADF9', padding: "5px 10px", display: 'inline-block' }}>
                                                    <Flexbox alignItems="center">
                                                        <TagOutlined sx={{ fontSize: 15 }} />
                                                        <Spacebox padding="2px" />
                                                        <small style={{ fontSize: '13px' }}>{doc.title}</small>
                                                    </Flexbox>
                                                </div>
                                                <Spacebox padding="3px" />
                                                <div style={{ background: `${doc.draft ? "#580e582a" : "#44c3c32a"} `, borderRadius: '10px', color: `${doc.draft ? "#580e58" : "#44c3c3"} `, padding: "5px 10px", display: 'inline-block' }}>
                                                    <Flexbox alignItems="center">
                                                        <Ping size={15} color={doc.draft ? "#580e58" : "#44c3c3"} />
                                                        <Spacebox padding="2px" />
                                                        <small style={{ fontSize: '13px' }}>{doc.draft ? "Draft" : "Published"}</small>
                                                    </Flexbox>
                                                </div>
                                            </Flexbox>
                                            <Spacebox padding="5px" />
                                            <Flexbox alignItems="center" justifyContent="space-between">
                                                <Flexbox alignItems="center">
                                                    <IconButton onClick={() => { }}>
                                                        <DateRangeOutlined sx={{ fontSize: 15 }} />
                                                    </IconButton>
                                                    <Spacebox padding="0.5px" />
                                                    <Typography sx={{ fontSize: '13px' }}>
                                                        {(new Date(doc.timestamp)).toString().substring(0, 15)}
                                                    </Typography>
                                                </Flexbox>
                                                <IconButton onClick={() => navigate(`/dashboard/manage/${doc.id}`)}>
                                                    <ChevronRight sx={{ width: 18 }} />
                                                </IconButton>
                                            </Flexbox>
                                        </div>
                                    )) : (<Typography>
                                        No documents found
                                    </Typography>)}
                                </CustomGrid>}
                            </div>
                        )}
                        {isMobile && (
                            <div>
                                {documents && <div>
                                    {documents.map((doc, index) => (
                                        <Flexbox justifyContent="space-between" alignItems="center" style={{ padding: '10px', background: '#f8f8f8', borderRadius: '10px', margin: '10px auto' }} key={index}>
                                            <div>
                                                <Typography noWrap sx={{ fontSize: '16px' }} className="">
                                                    {String.shorten(doc.title, 15)}
                                                </Typography>
                                                <Spacebox padding="0px" />
                                                <small style={{ opacity: '.6' }}>
                                                    {(new Date(doc.timestamp)).toString().substring(0, 15)} • {doc.active ? "Active" : "Inactive"}
                                                </small>
                                            </div>
                                            <Flexbox alignItems="Center">
                                                <IconButton onClick={() => duplicateDoc(doc)}>
                                                    <ContentCopyOutlined sx={{ fontSize: 15 }} />
                                                </IconButton>
                                                <Spacebox padding="10px" />
                                                <IconButton onClick={() => navigate(`/dashboard/manage/${doc.id}`)}>
                                                    <ChevronRight sx={{ width: 18 }} />
                                                </IconButton>
                                            </Flexbox>
                                        </Flexbox>
                                    ))}
                                    <Spacebox padding="10px" />
                                </div>}
                            </div>
                        )}
                        <Spacebox padding="10px" />
                        <ArrowPagination data={paginationDocuments} limit={4} setShowData={setDocuments} />
                    </div>
                )}
            </Container>
        </OnAuth>
    );
}

export default Manage;