import { useParams } from "react-router-dom"
import Toast from "../components/Toast"
import React, { useState, useRef } from 'react';
import SignaturePad from 'react-signature-canvas';
import Flexbox from "../components/Flexbox"
import Spacebox from "../components/styles/Spacebox";
import { isMobile } from "react-device-detect";
import requests from "../handlers/requests";
import { Divider, Skeleton, Typography } from "@mui/material";
import CustomButton from "../components/styles/Custombutton";
import { Ring } from "@uiball/loaders";
import '../css/sign.css'

const Sign = ({ title }) => {

    document.querySelector("title").innerHTML = title
    const { uid, id } = useParams()

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');
    const [loading, setLoading] = useState(true)

    const [isLoading, setIsLoading] = useState(false)
    const [complete, setComplete] = useState(false)

    const [activeDocument, setActiveDocument] = useState(true)
    const [document_, setDocument] = useState(null)

    // popup details
    const [firstName, setFirstname] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [openPopup, setOpenPopUp] = useState(true)
    const [poopupLoading, setPopupLoading] = useState(false)

    const [closeAction, setCloseAction] = useState(false)

    const sigCanvasRef = useRef(null);

    const clear = () => {
        sigCanvasRef.current.clear();
    };

    const save = () => {
        setIsLoading(true)
        const dataURL = sigCanvasRef.current.toDataURL();
        console.log(dataURL); // You can save this dataURL to your backend or display it
        requests.makePost(`https://app.octakode.xyz/api/upload-signature`, { sig: dataURL, uid: uid, did: id, phone: phone, email: email, fullname: `${firstName} ${lastName}`, firstname: firstName, lastname: lastName }, setOpen, setSeverity, setToastMsg, setIsLoading,
            (_) => {
                setComplete(true)
                setDocument(null)
            },
            "Document signed successfully"
        )
    };

    const brandBody = (data) => {
        if ("company_address" in data) {
            let margin = (data.branding_position).split(" ")[0] === 'top' ? "margin-bottom: 40px" : "margin-top: 40px"


            return `<p style="text-align: ${(data.branding_position).split(" ")[1]}; ${margin}">
            <b style="text-transform: uppercase; letter-spacing: 3px; font-size: small">${data.company_name}</b>,<br />
            ${data.company_address},<br />
            ${data.company_state}, ${data.company_zipcode},<br />
            ${data.company_country}.
            </p>`
        }

        return ""
    }

    const getDocument = (visitor) => {
        requests.makeGet(`https://app.octakode.xyz/api/get-document?uid=${uid}&did=${id}`, setOpen, setSeverity, setToastMsg, setLoading,
            (res) => {
                if (res.data.active) {
                    // document is active
                    // updating the document body with visitor's data
                    let doc = res.data
                    let docbody = doc.body
                    docbody = docbody.replaceAll("{{fullname}}", visitor.fullname)
                    docbody = docbody.replaceAll("{{ fullname }}", visitor.fullname)
                    docbody = docbody.replaceAll("{{email}}", visitor.email)
                    docbody = docbody.replaceAll("{{ email }}", visitor.email)
                    docbody = docbody.replaceAll("{{phone}}", visitor.phone)
                    docbody = docbody.replaceAll("{{ phone }}", visitor.phone)
                    docbody = docbody.replaceAll("{{firstname}}", visitor.firstname)
                    docbody = docbody.replaceAll("{{ firstname }}", visitor.firstname)
                    docbody = docbody.replaceAll("{{lastname}}", visitor.lastname)
                    docbody = docbody.replaceAll("{{ lastname }}", visitor.lastname)
                    docbody = docbody.replaceAll("{{date}}", (new Date(Date.now())).toString().substring(0, 16))
                    docbody = docbody.replaceAll("{{ date }}", (new Date(Date.now())).toString().substring(0, 16))

                    if (doc.branding_position.split(" ")[0] === "top") {
                        docbody = brandBody(doc) + docbody
                    } else {
                        docbody = docbody + brandBody(doc)
                    }
                    doc.body = docbody
                    setDocument(doc.body)
                    setOpenPopUp(false)
                } else {
                    // document not active
                    setActiveDocument(false)
                    setOpenPopUp(false)
                }
            },
            null
        )
    }

    const verify = () => {
        if (firstName !== "" && lastName !== "" && email.includes("@") && phone.length > 8) {
            setPopupLoading(true)
            getDocument({ firstname: firstName, lastname: lastName, email: email, phone: phone, fullname: firstName + " " + lastName })
        } else {
            setToastMsg("Missing/Invalid data")
            setSeverity('error')
            setOpen(true)
        }
    }

    return (
        <div className="sign-page" style={{ background: '#f0f0f0', minHeight: '100vh', }}>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <div className="" style={{ background: 'var(--primary)', height: '10px', borderRadius: '0px', border: "0px solid transparent", position: 'fixed', top: 0, left: 0, width: isMobile ? '100%' : 'calc(100%)', zIndex: 10, }}></div>
            <Flexbox justifyContent="space-between" alignItems="center" style={{ backgroundColor: "white", padding: '15px', borderBottom: '0px solid #ababab3a', position: 'fixed', top: 10, left: 0, width: isMobile ? 'calc(100% - 30px)' : 'calc(100%)', zIndex: 10, }}>
                <div className="logo-icon" style={{ paddingLeft: "10px" }}>
                    <img src="/assets/logo_icon.png" alt="docly" />
                </div>
            </Flexbox>
            <Spacebox padding="60px" />
            {(loading && !document_) && (
                <Flexbox justifyContent="center">
                    {isMobile && <div style={{width: 'calc(100% - 40px)', padding: '20px'}}>
                        <Skeleton variant="rounded" width={ 200} height={10} />
                        <Spacebox padding="2px" />
                        <Skeleton variant="rounded" width={ 200} height={10} />
                        <Spacebox padding="2px" />
                        <Skeleton variant="rounded" width={ 200} height={10} />
                        <Spacebox padding="5px" />
                        <Skeleton variant="rounded" width={'100%'} height={"70vh"} />
                    </div>}
                    {!isMobile && <div>
                        <Skeleton variant="rounded" width={ 200} height={10} />
                        <Spacebox padding="2px" />
                        <Skeleton variant="rounded" width={ 200} height={10} />
                        <Spacebox padding="2px" />
                        <Skeleton variant="rounded" width={ 200} height={10} />
                        <Spacebox padding="5px" />
                        <Skeleton variant="rounded" width={600} height={"70vh"} />
                    </div>}
                </Flexbox>
            )}
            {(!loading && document_) && (<div>
                <Flexbox justifyContent="center">
                    <div style={{ background: 'white', padding: isMobile ? '20px' : '20px 40px', width: isMobile ? 'calc(90% - 40px)' : '800px', boxShadow: '0px 0px 3px #00000010' }} dangerouslySetInnerHTML={{ __html: document_ }}></div>
                </Flexbox>
                <div style={{ margin: '20px auto 0px auto', backgroundColor: '#d9d9d9', width: isMobile ? '90%' : '880px', padding: '5px 0px' }}>
                    <Typography style={{ letterSpacing: '3px', opacity: .7, textAlign: 'center', fontSize: '10px' }}>
                        SIGN BELOW
                    </Typography>
                </div>
                <div style={{ background: 'white', width: isMobile ? '90%' : '880px', boxShadow: '0px 0px 3px #00000010', margin: '0px auto 0px auto', padding: '20px 0px 0px' }} >
                    <Flexbox justifyContent="center">
                        <div style={{ width: 'fit-content', border: '2px solid #e0e0e0' }}>
                            <SignaturePad penColor="#555" ref={sigCanvasRef} />
                        </div>
                    </Flexbox>
                    <Spacebox padding="10px" />
                    <Divider light />
                    <Flexbox justifyContent="center">
                        <Spacebox padding="20px" style={{ width: '100%' }}>
                            <CustomButton backgroundColor="transparent" color="var(--primary)" borderRadius="0px" padding="0" handleClick={() => clear()} className="fullwidth">
                                Clear
                            </CustomButton>
                        </Spacebox>
                        <Divider light orientation="vertical" flexItem />
                        <Spacebox padding="20px" style={{ width: '100%' }}>
                            <CustomButton backgroundColor="transparent" color="var(--primary)" borderRadius="0px" padding="0" handleClick={() => save()} className="fullwidth">
                                {"Confirm"}
                            </CustomButton>
                        </Spacebox>
                    </Flexbox>
                </div>
            </div>)}
            {openPopup && (
                <Flexbox alignItems="center" justifyContent="center" style={{ position: 'fixed', width: '100%', height: '100%', backgroundColor: '#00000050', zIndex: 10, top: 0, left: 0 }}>
                    <div style={{ width: isMobile ? "95%" : '400px', background: '#ffffff', borderRadius: '20px', margin: "auto" }}>
                        <Spacebox padding={isMobile ? "40px 25px" : "40px"}>
                            <Typography variant="h6" className="bold" sx={{ textAlign: 'center', lineHeight: '1.2em', fontSize: '23px' }}>
                                Fill the form to get started
                            </Typography>
                            <Spacebox padding="5px" />
                            <Typography sx={{ textAlign: 'center', fontSize: 15, }}>
                                Provide the correct information to the form below
                            </Typography>
                            <Spacebox padding="10px" />
                            <input
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstname(e.target.value)}
                                placeholder="First Name"
                            />
                            <Spacebox padding="5px" />
                            <input
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Last Name"
                            />
                            <Spacebox padding="5px" />
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                            />
                            <Spacebox padding="5px" />
                            <input
                                type="tel"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder="Phone Number"
                            />
                        </Spacebox>
                        <Divider light />
                        <Flexbox alignItems="center" justifyContent="space-between">
                            <Spacebox padding="20px" style={{ width: '100%' }}>
                                <CustomButton backgroundColor="transparent" color="red" borderRadius="0px" padding="0" handleClick={() => {
                                    setCloseAction(true)
                                    setOpenPopUp(false)
                                    setLoading(false)
                                    setTimeout(() => {
                                        window.location.href = "https://script.octakode.xyz/doc-sales1"
                                    }, 3000)
                                }} className="fullwidth">
                                    {"Cancel"}
                                </CustomButton>
                            </Spacebox>
                            <Divider light orientation="vertical" flexItem />
                            <Spacebox padding="20px" style={{ width: '100%' }}>
                                <CustomButton backgroundColor="transparent" color="var(--secondary)" borderRadius="0px" padding="0" handleClick={() => {
                                    verify()
                                }} className="fullwidth">
                                    {poopupLoading ? "Please wait..." : "Continue"}
                                </CustomButton>
                            </Spacebox>
                        </Flexbox>
                    </div>
                </Flexbox>
            )}
            {!activeDocument && (
                <div>
                    <Typography textAlign="center">
                        This document is no longer active
                    </Typography>
                </div>
            )}
            {closeAction && (
                <div>
                    <Typography textAlign="center">
                        You will be redirected to the our homepage shortly
                    </Typography>
                </div>
            )}
            {(complete) && (
                <div>
                    <Typography textAlign="center">
                        Thank You!
                    </Typography>
                </div>
            )}
            {(isLoading) && (
                <Flexbox alignItems="center" justifyContent="center" style={{ position: 'fixed', width: '100%', height: '100%', backgroundColor: '#00000050', zIndex: 10, top: 0, left: 0 }}>
                    <div style={{ width: 'fit-content', background: '#ffffff', borderRadius: '20000px', margin: "auto", padding: '20px' }}>
                        <Ring color="var(--primary)" size={30} />
                    </div>
                </Flexbox>
            )}
            <Spacebox padding="20px" />
            <div style={{ padding: '5px 15px', borderTop: '1px solid #d9d9d9', position: 'fixed', bottom: 0, left: 0, width: 'calc(100% - 30px)', zIndex: 10, background: '#f0f0f0' }}>
                <Flexbox justifyContent="space-between" alignItems="center">
                    <Flexbox alignItems="center">
                        <small style={{ color: 'grey', fontSize: isMobile ? 8 : 12 }}>Powered by</small>
                        <Spacebox padding="5px" />
                        <img src="/assets/docly_logo.png" alt="docly" style={{ width: 120, opacity: .5 }} />
                    </Flexbox>
                    <small style={{ color: 'grey', fontSize: isMobile ? 8 : 12 }}>
                        Copyright © {(new Date()).getFullYear()} Octakode Docly
                    </small>
                </Flexbox>
            </div>
        </div>
    );
}

export default Sign;