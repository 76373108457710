import { Container } from "@mui/material";
import Toast from "../components/Toast";
import OnAuth from "../components/onAuth";
import Flexbox from "../components/Flexbox";
import { Jelly } from "@uiball/loaders";
import Spacebox from "../components/styles/Spacebox";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateuser } from "../features/users";
import cookies from "../utilities/Cookies";

const AuthcallBack = ({title}) => {

    document.querySelector("title").innerHTML = title
    const UrlParams = new URLSearchParams(window.location.search)
    const code = UrlParams.get('code');
    const error = UrlParams.get('error');
    const token = cookies.getCookies('token')


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector(state => state.user.value)

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    useEffect(() => {
        if (code) {
            const redirect = 'https://docly.octakode.xyz/dashboard/oauth2callback'
            fetch(process.env.REACT_APP_SERVER + `/confirm-user-code?code=${code}&uid=${user.id}&redirect=${redirect}&token=${token}`)
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        setToastMsg('Successful')
                        setOpen(true)
                        setSeverity('success')
                        dispatch(updateuser({...res.user}))
                        setTimeout(() => {
                            navigate('/dashboard')
                        }, 3000);
                    } else {
                        console.log(res.error)
                        setToastMsg(res.msg)
                        setOpen(true)
                        setSeverity('error')
                        setTimeout(() => {
                            navigate('/dashboard')
                        }, 3000);
                    }
                }).catch(err => {
                    console.log("Error message: ", err.message)
                    setToastMsg("A fetch error occured")
                    setOpen(true)
                    setSeverity('error')
                    setTimeout(() => {
                        navigate('/dashboard')
                    }, 3000);
                })
        } else if(error) {
            console.log(error)
            setToastMsg('Octakode Docly requires access to your google sheets')
            setOpen(true)
            setSeverity('error')
            setTimeout(() => {
                navigate('/dashboard')
            }, 4000);
        }else{
            setToastMsg('An error occured')
            setOpen(true)
            setSeverity('error')
            setTimeout(() => {
                navigate('/dashboard')
            }, 4000);
        }
        // eslint-disable-next-line
    }, [])


    return (
        <OnAuth>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Container className="auth-callback">
                <Spacebox padding="20px" />
                <Flexbox justifyContent="center">
                    <Jelly size={40} color="var(--primary)" />
                </Flexbox>
            </Container>
        </OnAuth>
    );
}

export default AuthcallBack;