import { Container, Divider, FormControlLabel, FormGroup, IconButton, Switch, Typography } from "@mui/material";
import OnAuth from "../components/onAuth";
import Toast from "../components/Toast";
import { useNavigate, useParams } from "react-router-dom";
import cookies from "../utilities/Cookies";
import '../css/document.css'
import { useSelector } from "react-redux";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import { useEffect, useState } from "react";
import Flexbox from "../components/Flexbox";
import Spacebox from "../components/styles/Spacebox";
import { Jelly, Ping } from "@uiball/loaders";
import CustomButton from "../components/styles/Custombutton";
import CustomGrid from "../components/styles/Grid";
import download from 'downloadjs';
import { Close, ContentCopy, DownloadOutlined, Print, SaveOutlined } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import Highlight from "react-highlight";
import { isMobile } from "react-device-detect";



const Document = ({ title }) => {

    document.querySelector("title").innerHTML = title
    const user = useSelector(state => state.user.value)
    const token = cookies.getCookies('token')
    const { id } = useParams()
    const documentLink = `https://docly.octakode.xyz/sign/${user.id}/${id}`

    const navigate = useNavigate()

    const [currentTab, setCurrentTab] = useState(0)
    const navigators = [
        {
            name: 'Edit Document',
        },
        {
            name: 'View Signatories',
        },
        {
            name: 'Embed Code',
        },
    ]

    // React Quill Toolbar Options
    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote'],

        [{ 'list': 'ordered' }, { 'list': 'bullet' }],

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    ];

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');


    const [isLoading, setIsLoading] = useState(false)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [document_, setDocument] = useState(null)
    const [title_, setTitle] = useState(null)
    const [sheet, setSheet] = useState('')
    const [documentBody, setDocumentBody] = useState('')
    const [active, setActive] = useState(null)
    const [draft, setDraft] = useState(true)

    const [openShare, setOpenShare] = useState(false)
    const shareOptions = [
        { medium: 'LinkedIn', icon: '/svgs/linkedin.svg', sub: 'post', url: `https://www.linkedin.com/feed/?shareActive=true&shareUrl=${documentLink}` },
        { medium: 'X', icon: '/svgs/twitter.svg', sub: 'tweet', url: `https://x.com/share?text=Sign%20the%20document%20below&url=${documentLink}` },
        { medium: 'Gmail', icon: '/svgs/mail.svg', sub: 'message', url: `https://mail.google.com/mail/?view=cm&fs=1&su=Email%20Subject%20Here&body=${documentLink}` },
    ]

    const loadDocument = () => {
        setLoading(true)
        fetch(process.env.REACT_APP_SERVER + `/get-document?uid=${user.id}&token=${token}&id=${id}`)
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    setTitle(res.data.title)
                    setSheet(res.data.sheets)
                    setDocumentBody(res.data.body)
                    setActive(res.data.active)
                    setDraft(res.data.draft)
                    setDocument(res.data)
                    setLoading(false)
                } else {
                    console.log(res.error)
                    setError(res.msg)
                    setLoading(false)
                }
            }).catch(err => {
                console.log("Error message: ", err.message)
                setError('An error occured')
                setLoading(false)
            })
    }

    // Signatories bit
    const [signatories, setSignatories] = useState(null)
    const [selectedSignatories, setSelectedSignatories] = useState([])

    const columns = [
        { field: 'name', headerName: 'First name', minWidth: isMobile ? 100 : 200 },
        { field: 'email', headerName: 'Email', minWidth: isMobile ? 100 : 200 },
        { field: 'phone', headerName: 'Phone', minWidth: isMobile ? 100 : 200 },
        { field: 'signature', headerName: 'Signature', width: isMobile ? 100 : 200, sortable: false, renderCell: (params) => (<img alt={params.row.name} src={params.row.signature} style={{ width: isMobile ? 70 : 120 }} />) },
        { field: 'tag', headerName: 'Tag', minWidth: 120 },
        {
            field: 'timestamp', headerName: 'Signed On', minWidth: isMobile ? 70 : 120,
            valueGetter: (params) => (new Date(params.row.timestamp)).toString().substring(3, 15)
        },
        {
            field: 'download_url', headerName: 'Print', minWidth: isMobile ? 50 : 100, sortable: false,
            renderCell: (params) => (<a href={params.row.download_url} target="_blank" rel="noreferrer">
                <Print style={{ color: "grey" }} />
            </a>)
        },
    ]

    const loadSignatories = () => {
        setLoading(true)
        fetch(process.env.REACT_APP_SERVER + `/get-document-signatories?uid=${user.id}&token=${token}&id=${id}`)
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    setSignatories(res.data)
                    setLoading(false)
                } else {
                    console.log(res.error)
                    setError(res.msg)
                    setLoading(false)
                }
            }).catch(err => {
                console.log("Error message: ", err.message)
                setError('An error occured')
                setLoading(false)
            })
    }

    const updateDocument = (draft) => {
        if (title_ !== '' && sheet !== '' && documentBody.length > 30) {
            setIsLoading(true)
            fetch(process.env.REACT_APP_SERVER + '/update-document?token=' + token + `&uid=${user.id}&id=${id}`, {
                mode: 'cors',
                method: 'POST',
                headers: { "Content-Type": "application/json", "Accept": "application/json", "Origin": "http://localhost:3000" },
                body: JSON.stringify({
                    title: title_, body: documentBody, sheets: sheet, draft: draft, active: active
                })
            }).then(res => res.json())
                .then(res => {
                    if (res.success) {
                        if (draft)
                            setToastMsg("Draft saved")
                        else
                            setToastMsg("Document updated successfully")
                        setOpen(true)
                        setSeverity('success')
                        setIsLoading(false)
                        loadDocument()
                    } else {
                        console.log(res.error)
                        setToastMsg(res.msg)
                        setOpen(true)
                        setSeverity('error')
                        setIsLoading(false)
                    }
                }).catch(err => {
                    console.log("Error message: ", err.message)
                    setToastMsg('An error occured')
                    setOpen(true)
                    setSeverity('error')
                    setIsLoading(false)
                })
        } else {
            setToastMsg('Insufficient information')
            setOpen(true)
            setSeverity('error')
        }
    }

    const handleSheetId = (e) => {
        let link = e.target.value
        if (link.includes("/edit") && link.includes("https://docs.google.com/spreadsheets/d/")) {
            link = link.replace("https://docs.google.com/spreadsheets/d/", "")
            link = link.split("/edit")[0]
            setSheet(link)
        } else {
            setToastMsg('Invalid sheets url')
            setOpen(true)
            setSeverity('error')
        }
    }


    const downloadCSV = () => {
        if (selectedSignatories.length < 1) {
            setToastMsg('No signatories selected')
            setOpen(true)
            setSeverity('error')
            return
        }
        let selected = []
        for (let x in selectedSignatories) {
            for (let y in signatories) {
                if (signatories[y].id === selectedSignatories[x]) {
                    selected.push(signatories[y])
                }
            }
        }

        setIsLoading(true)
        fetch(process.env.REACT_APP_SERVER + `/download-signatories?token=${token}`, {
            mode: 'cors',
            method: 'POST',
            headers: { "Content-Type": "application/json", "Accept": "application/json", "Origin": "http://localhost:3000" },
            body: JSON.stringify({
                data: selected
            })
        })
            .then(res => res.blob())
            .then(blob => {
                setToastMsg("Signatories downloaded successfully")
                setOpen(true)
                setSeverity('success')
                setIsLoading(false)
                download(blob, `${title_}-${Date.now()}.csv`, 'text/plain');
            }).catch(err => {
                console.log("Error message: ", err.message)
                setToastMsg('An error occured')
                setOpen(true)
                setSeverity('error')
                setIsLoading(false)
            })
    }

    const sendToSheets = () => {
        if (selectedSignatories.length < 1) {
            setToastMsg('No signatories selected')
            setOpen(true)
            setSeverity('error')
            return
        }
        let selected = []
        for (let x in selectedSignatories) {
            for (let y in signatories) {
                if (signatories[y].id === selectedSignatories[x]) {
                    selected.push(signatories[y])
                }
            }
        }

        setIsLoading(true)

        fetch(process.env.REACT_APP_SERVER + `/google-sheets?uid=${user.id}&token=${token}&did=${id}`, {
            mode: 'cors',
            method: 'POST',
            headers: { "Content-Type": "application/json", "Accept": "application/json", "Origin": "http://localhost:3000" },
            body: JSON.stringify({
                data: selected
            })
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    setToastMsg("Signatories set to Google Sheets")
                    setOpen(true)
                    setSeverity('success')
                    setIsLoading(false)
                } else {
                    console.log(res.error)
                    setToastMsg(res.msg)
                    setOpen(true)
                    setSeverity('error')
                    setIsLoading(false)
                }
            }).catch(err => {
                console.log("Error message: ", err.message)
                setToastMsg('An error occured')
                setOpen(true)
                setSeverity('error')
                setIsLoading(false)
            })
    }

    // Embed Bit
    const [redirect, setRedirect] = useState('')
    const [terms, setTerms] = useState('')
    const [showSnipet, setShowSnipet] = useState(false)

    const verifyInput = () => {
        if ((redirect.includes("https") || redirect.includes("http")) && (terms.includes("https") || terms.includes("http"))) {
            setShowSnipet(true)
        } else {
            setToastMsg("Invalid link")
            setSeverity("error")
            setOpen(true)
        }
    }

    const saveTemplate = () => {
        if (title_ !== '' && sheet !== '' && documentBody.length > 30) {
            setIsLoading(true)
            fetch(process.env.REACT_APP_SERVER + `/save-template?uid=${user.id}&token=${token}&id=${id}`)
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        setToastMsg("Template saved")
                        setOpen(true)
                        setSeverity('success')
                        setIsLoading(false)
                        setTimeout(() => {
                            navigate('/dashboard/templates')
                        }, 4000);
                    } else {
                        console.log(res.error)
                        setToastMsg(res.msg)
                        setOpen(true)
                        setSeverity('error')
                        setIsLoading(false)
                    }
                }).catch(err => {
                    console.log("Error message: ", err.message)
                    setToastMsg('An error occured')
                    setOpen(true)
                    setSeverity('error')
                    setIsLoading(false)
                })
        } else {
            setToastMsg('Incomplete Information')
            setOpen(true)
            setSeverity('error')
        }
    }

    useEffect(() => {
        loadDocument()
        loadSignatories()

        // eslint-disable-next-line
    }, [])

    return (
        <OnAuth>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Container className="manage-doc" sx={isMobile ? { paddingLeft: 0, paddingRight: 0 } : {}}>
                {error && (
                    <div>
                        <Spacebox padding="20px" />
                        <Typography>An error occured</Typography>
                    </div>
                )}
                {loading && (
                    <div>
                        <Spacebox padding="20px" />
                        <Flexbox justifyContent="center">
                            <Jelly size={20} color="var(--primary)" />
                        </Flexbox>
                    </div>
                )}
                <div>
                    <Flexbox justifyContent="space-between" alignItems="center">
                        <div>
                            {title_ ? (
                                <Flexbox alignItems="center">
                                    <Ping size={30} color={draft ? "#580e58" : "#44c3c3"} />
                                    <Spacebox padding="5px" />
                                    <Typography className="bold" variant="h5" style={isMobile ? { fontSize: '20px' } : {}}>
                                        {title_}
                                    </Typography>
                                    <Spacebox padding="5px" />
                                    <IconButton onClick={() => setOpenShare(true)}>
                                        <Flexbox alignItems="center" justifyContent="center" style={{ borderRadius: '100px', height: '35px', width: '35px', background: '#f8f8f8' }}>
                                            <img src="/svgs/share-2.svg" alt="share" style={{ width: '20px' }} />
                                        </Flexbox>
                                    </IconButton>
                                </Flexbox>
                            ) : ""}
                        </div>
                        {!isMobile && <CustomButton backgroundColor="var(--primary)" borderRadius="100px" color="white" handleClick={() => saveTemplate()} padding="15px 40px" className="">
                            {isLoading ? (<Flexbox justifyContent="center">
                                <Jelly size={15} color="white" />
                            </Flexbox>) : "Save As Template"}
                        </CustomButton>}
                        {isMobile && <IconButton onClick={() => saveTemplate()}>
                            <SaveOutlined style={{ fontSize: '20px', color: 'green' }} />
                        </IconButton>}
                    </Flexbox>
                </div>
                <Spacebox padding="10px" />
                <div className="navigators">
                    <Flexbox alignItems="center" >
                        {navigators.map((nav, index) => (
                            <div style={{ backgroundColor: index === currentTab && 'white', boxShadow: index === currentTab && 'rgba(86, 97, 107, 0.1) 0px 1px 2px 0px' }} className="cust-btn" key={index} onClick={() => setCurrentTab(index)}>
                                <span style={{ color: index === currentTab ? '#131316' : '#56616B' }}>{nav.name}</span>
                            </div>
                        ))}
                    </Flexbox>
                </div>
                <Spacebox padding="20px" />
                {currentTab === 0 && (
                    <div>
                        {document_ && <div className="edit-document">
                            <Typography>Edit Document</Typography>
                            <Spacebox padding="10px" />
                            <Spacebox padding="2px">
                                <Typography style={{ fontSize: '18px' }}>
                                    Document Name
                                </Typography>
                            </Spacebox>
                            <input
                                type="text"
                                value={title_}
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder="Enter document title"
                            />
                            <Spacebox padding="2px">
                                <small style={{ color: 'grey' }}>
                                    This also serve as each document's signatory tag name
                                </small>
                            </Spacebox>
                            <Spacebox padding="10px" />
                            <Spacebox padding="2px">
                                <Typography style={{ fontSize: '18px' }}>
                                    {active ? "Disable" : "Activate"} Document
                                </Typography>
                            </Spacebox>

                            <FormGroup>
                                <FormControlLabel control={<Switch value={active} defaultChecked={active} onChange={(e) => setActive(e.target.checked)} inputProps={{ 'aria-label': 'controlled' }} />} color="primary" />
                            </FormGroup>
                            <Spacebox padding="2px">
                                <small style={{ color: 'grey' }}>
                                    Contract is curretly {active ? "active" : "inactive"} <br />
                                    An inactive contract will display a prompt informing users that it can't be signed, while an active contract will be signable by users
                                </small>
                            </Spacebox>
                            <Spacebox padding="10px" />
                            <Spacebox padding="2px">
                                <Typography style={{ fontSize: '18px' }}>
                                    Google Sheets ID
                                </Typography>
                            </Spacebox>
                            <input
                                type="text"
                                value={sheet}
                                onChange={handleSheetId}
                                placeholder="Enter google Sheets id"
                            />
                            <Spacebox padding="2px">
                                <small style={{ color: 'grey' }}>
                                    Don't know how to get this ? <a href="https://medium.com/@ujumaduowen/how-to-get-google-sheets-id-google-developer-console-api-key-client-id-for-octakode-script-cc66d395c686" target="_blank" rel="noreferrer">Click here</a>
                                </small>
                            </Spacebox>
                            <Spacebox padding="10px" />
                            <Spacebox padding="2px">
                                <Typography style={{ fontSize: '18px' }}>
                                    Document Body
                                </Typography>
                            </Spacebox>
                            <ReactQuill theme="snow" placeholder="Enter Document body here..." modules={{ toolbar: toolbarOptions }} value={documentBody} onChange={setDocumentBody} />
                            <Spacebox padding="2px">
                                <small style={{ color: 'grey' }}>
                                    {`Variable placeholders should be place inside doble curly braces. Example, to populate the firstname, {{ firstname }}, {{ email }} for email, {{ phone }} for phone number, {{ fullname }} for fullname, {{ date }} for date and {{ lastname }} for last name.`}
                                    <br /><b>Note:</b> Variable names are tied to respective input fields on your optin / order form page.
                                </small>
                            </Spacebox>
                            <Spacebox padding="10px" />
                            <CustomGrid gap={isMobile ? "10px" : "20px"} grid={isMobile ? 1 : 2}>
                                <CustomButton backgroundColor="var(--secondary)" borderRadius="20px" color="white" handleClick={() => updateDocument(true)} padding="15px" className="fullwidth">
                                    {isLoading ? (<Flexbox justifyContent="center">
                                        <Jelly size={15} color="white" />
                                    </Flexbox>) : "Save As Draft"}
                                </CustomButton>
                                <CustomButton backgroundColor="var(--primary)" borderRadius="20px" color="white" handleClick={() => updateDocument(false)} padding="15px" className="fullwidth">
                                    {isLoading ? (<Flexbox justifyContent="center">
                                        <Jelly size={15} color="white" />
                                    </Flexbox>) : "Save Changes then Publish"}
                                </CustomButton>
                            </CustomGrid>
                            <Spacebox padding="20px" />
                        </div>}
                    </div>
                )}
                {currentTab === 1 && (
                    <div>
                        {signatories && <div className="signatories">
                            <Flexbox justifyContent="space-between" alignItems="center">
                                <Typography className="bold">
                                    Signatories
                                </Typography>
                                <Flexbox alignItems="center">
                                    {!isMobile && <CustomButton backgroundColor="var(--primary)" borderRadius="100px" color="white" handleClick={downloadCSV} style={{ border: '2px solid var(--primary)' }} padding="10px 40px" className="fullwidth">
                                        {isLoading ? (<Flexbox justifyContent="center">
                                            <Jelly size={15} color="white" />
                                        </Flexbox>) : (<Flexbox alignItems="center">
                                            <DownloadOutlined style={{ fontSize: 20 }} />
                                            <Spacebox padding="5px" />
                                            <span style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>Download Signatories</span>
                                        </Flexbox>)}
                                    </CustomButton>}
                                    {isMobile && <div onClick={sendToSheets}>
                                        {isLoading ? (<Flexbox justifyContent="center">
                                            <Jelly size={15} color="white" />
                                        </Flexbox>) : (<Flexbox alignItems="center">
                                            <DownloadOutlined style={{ fontSize: 25 }} />
                                        </Flexbox>)}
                                    </div>}
                                    {isMobile && <Spacebox padding="10px" />}
                                    <Spacebox padding="10px" />
                                    {!isMobile && <CustomButton backgroundColor="transparent" borderRadius="100px" color="#27A667" handleClick={sendToSheets} style={{ border: '2px solid #27A667' }} padding="10px 40px" className="fullwidth">
                                        {isLoading ? (<Flexbox justifyContent="center">
                                            <Jelly size={15} color="#27a667" />
                                        </Flexbox>) : (<Flexbox alignItems="center">
                                            <img src="/svgs/google-sheets-icon.svg" style={{ width: 15 }} alt="google-sheets" />
                                            <Spacebox padding="5px" />
                                            <span>Send to Sheets</span>
                                        </Flexbox>)}
                                    </CustomButton>}
                                    {isMobile && <div onClick={sendToSheets}>
                                        {isLoading ? (<Flexbox justifyContent="center">
                                            <Jelly size={15} color="#27a667" />
                                        </Flexbox>) : (<Flexbox alignItems="center">
                                            <img src="/svgs/google-sheets-icon.svg" style={{ width: 15 }} alt="google-sheets" />
                                        </Flexbox>)}
                                    </div>}
                                </Flexbox>
                            </Flexbox>
                            <Spacebox padding="10px" />
                            <Typography sx={{ fontSize: '18px' }}>
                                Selected Signatories: {selectedSignatories.length}
                            </Typography>
                            <Spacebox padding="10px" />
                            {signatories.length > 0 && <DataGrid
                                columns={columns}
                                rows={signatories}
                                checkboxSelection
                                onRowSelectionModelChange={(selectedRow) => {
                                    setSelectedSignatories(selectedRow);
                                }}
                                rowSelectionModel={selectedSignatories}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 10 },
                                    },
                                }}
                                pageSizeOptions={[10, 20]}
                                density="comfortable"
                                sx={{ borderRadius: "20px", zIndex: 0, backgroundColor: 'white', boxShadow: '10px 10px 20px #f0f0f0' }}
                            />}
                            {signatories.length < 1 && <Flexbox justifyContent="center">
                                <Typography sx={{ fontSize: '18px' }}>You currently have no signatories</Typography>
                            </Flexbox>}
                        </div>}
                    </div>
                )}
                {currentTab === 2 && (
                    <div className="embed-codes">
                        <CustomGrid grid={isMobile ? 1 : 2} gap="10px">
                            <div>
                                <Typography className="bold">
                                    Redirect Url
                                </Typography>
                                <span>Provide the details below</span>
                                <Spacebox padding="20px" />
                                <Spacebox padding="2px">
                                    <Typography style={{ fontSize: '18px' }}>
                                        Redirect Url
                                    </Typography>
                                </Spacebox>
                                <input
                                    type="text"
                                    value={redirect}
                                    onChange={(e) => setRedirect(e.target.value)}
                                    placeholder="Enter a valid redirect url"
                                />
                                <Spacebox padding="2px">
                                    <small style={{ color: 'grey' }}>
                                        Enter a valid url begining with https:// or http://
                                    </small>
                                </Spacebox>
                                <Spacebox padding="10px" />
                                <Spacebox padding="2px">
                                    <Typography style={{ fontSize: '18px' }}>
                                        Terms of service Url
                                    </Typography>
                                </Spacebox>
                                <input
                                    type="text"
                                    value={terms}
                                    onChange={(e) => setTerms(e.target.value)}
                                    placeholder="Enter a valid url"
                                />
                                <Spacebox padding="2px">
                                    <small style={{ color: 'grey' }}>
                                        Enter a valid url begining with https:// or http://
                                    </small>
                                </Spacebox>
                                <Spacebox padding="10px" />
                                <CustomButton backgroundColor="var(--primary)" borderRadius="20px" color="white" handleClick={verifyInput} padding="15px" className="fullwidth">
                                    Generate Code
                                </CustomButton>
                            </div>
                        </CustomGrid>
                        <Spacebox padding="20px" />
                        {(showSnipet) && <div>
                            <Typography textAlign="center" variant="h5" className="bold" style={isMobile ? { fontSize: '25px' } : {}}>
                                Code Snipet Installation
                            </Typography>
                            <Spacebox padding="5px" />
                            <Typography textAlign="center" style={{ fontSize: '18px' }}>
                                Follow each code snipet directives to ensure proper integration.
                            </Typography>
                            <Spacebox padding="20px" />
                            <Typography className="bold">
                                Contract Page Snipet
                            </Typography>
                            <Spacebox padding="10px" />
                            <Flexbox alignItems="center">
                                <IconButton onClick={() => {
                                    window.navigator.clipboard.writeText(`<link rel="stylesheet" href="https://app.octakode.xyz/styles/signature.css"/>`)
                                    setToastMsg("Code Copied")
                                    setSeverity('success')
                                    setOpen(true)
                                }}>
                                    <ContentCopy style={{ fontSize: '18px' }} />
                                </IconButton>
                                <Spacebox padding="2px" />
                                <small>Copy Code</small>
                            </Flexbox>
                            <Highlight className="html">
                                {`<link rel="stylesheet" href="https://app.octakode.xyz/styles/v1/signature.css"/>`}
                            </Highlight>
                            <small style={{ color: 'grey' }}>Add the above code to the html header section on your contract page.</small>
                            <Spacebox padding="20px" />
                            <Flexbox alignItems="center">
                                <IconButton onClick={() => {
                                    window.navigator.clipboard.writeText(`<div class="ocs_document"><div class="host_document_holder"><div class="host_document"></div></div><div class="ocs_check"><input type="checkbox" name="accept" id="accept"><label for="accept"><a href="${terms}" target="_blank">I accept the terms and services</a></label></div><div class="sign-here-ocs"><span style="display: inline-block; margin-right: 5px; letter-spacing: 3px; font-size: 13px;"> PLEASE SIGN HERE </span></div><div class="ocs_signature"><canvas id="signatureCanvas"></canvas></div><div class="grid2 gap20"><button id="ocs_clear"> clear </button><button id="ocs_submit"> Submit </button></div></div>`)
                                    setToastMsg("Code Copied")
                                    setSeverity('success')
                                    setOpen(true)
                                }}>
                                    <ContentCopy style={{ fontSize: '18px' }} />
                                </IconButton>
                                <Spacebox padding="2px" />
                                <small>Copy Code</small>
                            </Flexbox>
                            <Highlight className="html">
                                {`<div class="ocs_document"><div class="host_document"></div><div class="ocs_check"><input type="checkbox" name="accept" id="accept"><label for="accept"><a href="${terms}" target="_blank">I accept the terms and services</a></label></div><div class="sign-here-ocs"><span style="display: inline-block; margin-right: 5px; letter-spacing: 3px; font-size: 13px;"> PLEASE SIGN HERE </span></div><div class="ocs_signature"><canvas id="signatureCanvas"></canvas></div><div class="grid2 gap20"><button id="ocs_clear"> clear </button><button id="ocs_submit"> Submit </button></div></div>`}
                            </Highlight>
                            <small style={{ color: 'grey' }}>The above code will show the contact. This snipet should be placed on the page</small>
                            <Spacebox padding="20px" />
                            <Flexbox alignItems="center">
                                <IconButton onClick={() => {
                                    window.navigator.clipboard.writeText(`
                                    <script> 
                                        const ocs_uid = "${user.id}"; 
                                        const ocs_redirect_url = "${redirect}"; 
                                        const ocs_did = "${id}"; 
                                    </script>
                                    <script src="https://app.octakode.xyz/scripts/v1/signature.js"></script>`)
                                    setToastMsg("Code Copied")
                                    setSeverity('success')
                                    setOpen(true)
                                }}>
                                    <ContentCopy style={{ fontSize: '18px' }} />
                                </IconButton>
                                <Spacebox padding="2px" />
                                <small>Copy Code</small>
                            </Flexbox>
                            <Highlight className="html">
                                {`<script> const ocs_uid = "${user.id}"; const ocs_redirect_url = "${redirect}"; const ocs_did = "${id}"; </script><script src="https://app.octakode.xyz/scripts/signature.js"></script>`}
                            </Highlight>
                            <small style={{ color: 'grey' }}>Add the above code to the html footer section on your contract page.</small>
                            <Spacebox padding="20px" />
                            <Divider light />
                            <Spacebox padding="20px" />
                            <Typography>
                                Optin Page Snipet
                            </Typography>
                            <Spacebox padding="10px" />
                            <Flexbox alignItems="center">
                                <IconButton onClick={() => {
                                    window.navigator.clipboard.writeText(`<script src="https://app.octakode.xyz/scripts/inputstolocalstorage.js"></script>`)
                                    setToastMsg("Code Copied")
                                    setSeverity('success')
                                    setOpen(true)
                                }}>
                                    <ContentCopy style={{ fontSize: '18px' }} />
                                </IconButton>
                                <Spacebox padding="2px" />
                                <small>Copy Code</small>
                            </Flexbox>
                            <Highlight className="html">
                                {`<script src="https://app.octakode.xyz/scripts/inputstolocalstorage.js"></script>`}
                            </Highlight>
                            <small style={{ color: 'grey' }}>Add the above code to the html footer of the page where visitors enter their infomation</small>
                            <Spacebox padding="20px" />
                        </div>}
                    </div>
                )}
            </Container>
            {(openShare) && (
                <Flexbox alignItems="center" justifyContent="center" style={{ width: '100%', height: '100vh', background: '#00000030', position: 'fixed', top: 0, left: 0, zIndex: '100' }}>
                    <div className="mid" style={{ background: 'white', borderRadius: '20px', overflow: 'hidden' }}>
                        <Spacebox padding="20px">
                            <Flexbox alignItems="center" justifyContent="space-between">
                                <Typography><b><small>Share document</small></b></Typography>
                                <IconButton onClick={() => setOpenShare(false)}>
                                    <Close style={{ fontSize: '20px' }} />
                                </IconButton>
                            </Flexbox>
                            <Spacebox padding="5px" />
                            {shareOptions.map((option, index) => (
                                <div style={{ padding: '10px 0px' }} key={index}>
                                    <Flexbox alignItems="center" justifyContent="space-between">
                                        <Flexbox alignItems="center">
                                            <img src={option.icon} alt="" style={{ width: '20px', opacity: .5 }} />
                                            <Spacebox padding="20px" />
                                            <div>
                                                <span><b>Share on {option.medium}</b></span>
                                                <br />
                                                <small>Embed this document in a {option.sub}</small>
                                            </div>
                                        </Flexbox>
                                        <IconButton onClick={() => {
                                            window.open(option.url, '_blank', 'noopener,noreferrer');
                                        }}>
                                            <Flexbox alignItems="center" justifyContent="center" style={{ borderRadius: '100px', height: '35px', width: '35px', background: '#f8f8f8' }}>
                                                <img src="/svgs/share-2.svg" alt="share" style={{ width: '20px' }} />
                                            </Flexbox>
                                        </IconButton>
                                    </Flexbox>
                                </div>
                            ))}
                        </Spacebox>
                        <Spacebox padding="10px 20px" style={{ background: '#f0f0f0' }}>
                            <Flexbox alignItems="center" justifyContent="space-between">
                                <small>Anyone with this link can sign the document</small>
                                <IconButton onClick={() => {
                                    window.navigator.clipboard.writeText(documentLink)
                                    setSeverity('success')
                                    setToastMsg('Link copied')
                                    setOpen(true)
                                }}>
                                    <Flexbox alignItems="center">
                                        <img src="/svgs/link-2.svg" alt="" style={{ width: '15px', opacity: .5 }} />
                                        <Spacebox padding="3px" />
                                        <small style={{ fontSize: '15px' }}>Copy link</small>
                                    </Flexbox>
                                </IconButton>
                            </Flexbox>

                        </Spacebox>

                    </div>
                </Flexbox>
            )}
        </OnAuth>
    );
}

export default Document;