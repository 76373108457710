import { useEffect } from "react";
import cookies from "../utilities/Cookies";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux"

const OnAuth = ({ children }) => {

    const navigate = useNavigate();

    const height = useSelector(state => state.headerHeight.value)
    const user = useSelector(state => state.user.value)

    useEffect(() => {
        const token = cookies.getCookies('token');
        if (token === '' || token === undefined || token === null) {
            navigate('/');
        }
        // check if user trial is on
        if(Date.now() > user.trial_expires && user.trial === true) {
            fetch(process.env.REACT_APP_SERVER + "/trial-active?token=" + token)
            .then(res => res.json())
            .then(res => {
                if(res.success) {
                    console.log("Active ", res.active)
                    if(!res.active && window.location.pathname !== "/dashboard/pending" && window.location.pathname !== "/dashboard/plans")
                        navigate('dashboard/pending')
                }else{
                    console.log("While getting user trial status ", res.msg)
                }
            }).catch(err => {
                console.log("Error message: ", err.message)
            })
        }
        //eslint-disable-next-line
    }, [children])
    return (
        <div className={`authenticate-layout ${height.mobile === 0 && 'full'}`}>
            {children}
        </div>
    );
}

export default OnAuth;