import { Container, IconButton, Typography } from "@mui/material";
import OnAuth from "../components/onAuth";
import Toast from "../components/Toast";
import cookies from "../utilities/Cookies";
import useFetch from "../hooks/useFetch";
import { useState } from "react";
import { Jelly } from "@uiball/loaders";
import Flexbox from "../components/Flexbox";
import Spacebox from "../components/styles/Spacebox";
import CustomGrid from "../components/styles/Grid";
import { Close, PlayCircleOutline } from "@mui/icons-material";
import { isMobile } from "react-device-detect";

const Tutorials = ({ title }) => {

    document.querySelector("title").innerHTML = title
    const token = cookies.getCookies('token')
    const { data: tutorials, error, isLoading } = useFetch(process.env.REACT_APP_SERVER + `/get-tutorials?token=${token}`)


    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [query, setQuery] = useState('');
    const [searchTut, setSearchTut] = useState(null);
    const [selectedTutorial, setSelectedTutorial] = useState(null);

    const handleSearch = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            setQuery(e.target.value);
            if ((e.target.value).length > 0) {
                let res = tutorials.filter(tut => tut.keywords.includes(query))
                if (res.length > 0) {
                    setSearchTut(res)
                } else {
                    setToastMsg('No tutorial matched search')
                    setOpen(true)
                    setSeverity('error')
                }
            } else {
                setSearchTut(null)
            }
        }
    }

    const determineTutArray = () => {
        if (searchTut)
            return searchTut
        else
            return tutorials

    }


    return (
        <OnAuth>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Container className="tutorial-body">
                {error && (
                    <div>
                        <Spacebox padding="20px" />
                        <Typography>| An error occured!</Typography>
                    </div>
                )}
                {isLoading && (
                    <div>
                        <Spacebox padding="20px" />
                        <Flexbox justifyContent="center">
                            <Jelly size={20} color="var(--primary)" />
                        </Flexbox>
                    </div>
                )}
                {tutorials && (
                    <div>
                        <Spacebox padding="20px" />
                        <div className="mid margin-auto">
                            <Typography variant="h4" sx={{ textAlign: 'center', fontSize: isMobile ? "20px" : "3.03rem" }}>
                                WHAT DO YOU NEED HELP WITH?
                            </Typography>
                            <Spacebox padding="5px" />
                            <input
                                type="text"
                                className="tutorial-search"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                onKeyDown={handleSearch}
                                placeholder="Search for..."
                                style={{ fontSize: isMobile ? '20px' : '40px', borderRadius: '100px' }}
                            />
                        </div>
                        <Spacebox padding={isMobile ? "20px" : "40px"} />
                        {tutorials && <div>
                            <CustomGrid grid={isMobile ? 1 : 4} gap="20px">
                                {determineTutArray().map((tutorial, index) => (
                                    <div key={index} style={{ background: 'var(--primary)', borderRadius: '10px' }}>
                                        <Spacebox padding="20px">
                                            <Typography variant="h5" style={{ color: 'white' }}>
                                                {tutorial.title}
                                            </Typography>
                                            <Spacebox padding="10px" />
                                            <Flexbox justifyContent="right">
                                                <IconButton onClick={() => setSelectedTutorial(tutorial)}>
                                                    <PlayCircleOutline sx={{ color: 'white' }} />
                                                </IconButton>
                                            </Flexbox>
                                        </Spacebox>
                                    </div>
                                ))}
                            </CustomGrid>
                        </div>}
                    </div>
                )}
                {selectedTutorial && <Flexbox justifyContent="center" alignItems="center" className="preview-box" >
                    <div>
                        <div className="preview" style={{background: 'transparent'}}>
                            <div style={{ textAlign: 'right' }}>
                                <IconButton onClick={() => setSelectedTutorial(null)}>
                                    <Close style={{color: 'red'}}/>
                                </IconButton>
                            </div>
                            <Spacebox padding="10px" />
                            <iframe
                                src={selectedTutorial.youtube}
                                title={selectedTutorial.title}
                                frameborder="0"
                                style={{ 
                                    width: "100%",
                                    aspectRatio: "16/9",
                                    borderRadius: "20px",
                                    overflow: "hidden"
                                }}
                            ></iframe>
                        </div>
                    </div>
                </Flexbox>}
            </Container>
        </OnAuth>
    );
}

export default Tutorials;