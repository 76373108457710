import { useNavigate } from "react-router-dom";
import Flexbox from "./Flexbox";
import Spacebox from "./styles/Spacebox";
import { Avatar, Divider, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { updateHeaderHeight } from "../features/headerHeight";
import CustomButton from "./styles/Custombutton";
import cookies from "../utilities/Cookies";
import Toast from "./Toast";
import { isMobile } from "react-device-detect";

const Header = () => {

    const navigate = useNavigate()

    const user = useSelector(state => state.user.value)
    const token = cookies.getCookies("token")

    const [showMenu, setShowMenu] = useState(false)
    const [showRefer, setShowRefer] = useState(false)
    const [showReport, setShowReport] = useState(false)

    const [report, setReport] = useState("")
    const [loading, setLoading] = useState(false)

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');


    const laptop_nav = [
        {
            name: "Home",
            icon: "/svgs/home.svg",
            action: () => {
                navigate('/dashboard')
                setShowMenu(false)
            },
            slug: "/dashboard"
        },
        {
            name: "Manage",
            icon: "/svgs/sliders.svg",
            action: () => {
                navigate('/dashboard/manage')
                setShowMenu(false)
            },
            slug: "/dashboard/manage"
        },
        {
            name: "Templates",
            icon: "/svgs/copy.svg",
            action: () => {
                navigate('/dashboard/templates')
                setShowMenu(false)
            },
            slug: "/dashboard/templates"
        },
        {
            name: "Tutorials",
            icon: "/svgs/video.svg",
            action: () => {
                navigate('/dashboard/tutorials')
                setShowMenu(false)
            },
            slug: "/dashboard/tutorials"
        }
    ]

    const menu_nav = [
        {
            name: 'Account Settings',
            icons: "/svgs/settings.svg",
            action: () => {
                navigate('/dashboard/settings')
                setShowMenu(false)
            }
        },
        {
            name: 'Refer and Earn',
            icons: "/svgs/share.svg",
            action: () => {
                setShowRefer(true)
                setShowMenu(false)
            }
        },
        {
            name: 'Report an Issue',
            icons: "/svgs/report.svg",
            action: () => {
                setShowReport(true)
                setShowMenu(false)
            }
        },
        {
            name: 'Payment History',
            icons: "/svgs/help-circle.svg",
            action: () => {
                navigate('/dashboard/payments')
                setShowMenu(false)
            }
        },
        {
            name: 'Log out',
            icons: "/svgs/log-out.svg",
            action: () => logout()
        },
    ]

    const dispatch = useDispatch();

    const getHeaderHeight = () => {
        const heights = {
            laptop: document.querySelector('.header .hide-on-med-and-down').offsetHeight,
            mobile: document.querySelector('.header .hide-on-large-only').offsetHeight
        }

        dispatch(updateHeaderHeight(heights))
    }

    const logout = () => {
        setShowMenu(false)
        cookies.deleteCookies('token')
        cookies.deleteCookies('user')
        navigate('/')
    }

    const condition = (word) => {
        if (window.location.pathname === word)
            return true
    }

    const handleAffiliate = () => {
        if (user.affiliate) {
            window.location.href = 'https://app.octakode.xyz/login?aff=true'
        } else {
            setLoading(true)
            fetch(process.env.REACT_APP_SERVER + '/update-user-details?token=' + token, {
                method: 'POST',
                mode: 'cors',
                headers: { "Content-Type": "application/json", "Accept": "application/json", "Origin": 'http://localhost:3000' },
                body: JSON.stringify({ affiliate: true })
            }).then(res => res.json())
                .then(res => {
                    if (res.success) {
                        window.location.href = 'https://app.octakode.xyz/login?aff=true'
                    } else {
                        console.log(res.error)
                        setToastMsg(res.msg)
                        setOpen(true)
                        setSeverity('error')
                        setLoading(false)
                    }
                }).catch(err => {
                    console.log("Error message: ", err.message)
                    setToastMsg('An error occured')
                    setOpen(true)
                    setSeverity('error')
                    setLoading(false)
                })
        }
    }


    const handleReport = () => {
        if (report.length > 20) {
            setLoading(true)
            fetch(process.env.REACT_APP_SERVER + '/report?token=' + token, {
                method: 'POST',
                mode: 'cors',
                headers: { "Content-Type": "application/json", "Accept": "application/json", "Origin": 'http://localhost:3000' },
                body: JSON.stringify({ email: user.email, firstname: user.firstname, uid: user.id, report })
            }).then(res => res.json())
                .then(res => {
                    if (res.success) {
                        setToastMsg("Report sent successfully")
                        setOpen(true)
                        setSeverity('success')
                        setLoading(false)
                        setReport("")
                        setShowReport(false)
                    } else {
                        console.log(res.error)
                        setToastMsg(res.msg)
                        setOpen(true)
                        setSeverity('error')
                        setLoading(false)
                    }
                }).catch(err => {
                    console.log("Error message: ", err.message)
                    setToastMsg('An error occured')
                    setOpen(true)
                    setSeverity('error')
                    setLoading(false)
                })
        } else {
            setToastMsg("Report too short")
            setOpen(true)
            setSeverity('error')
            setLoading(false)
        }
    }

    useEffect(() => {
        getHeaderHeight()
        //eslint-disable-next-line
    }, [])

    return (
        <div className="header">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <div className="hide-on-med-and-down fixed fullwidth top0" style={{ zIndex: 10 }}>
                <div className="" style={{ background: 'var(--primary)', height: '10px', borderRadius: '0px', border: "0px solid transparent" }}></div>
                <Spacebox padding="0px">
                    <Flexbox justifyContent="space-between" alignItems="center" style={{ backgroundColor: "white", padding: '15px', borderBottom: '1px solid #ababab3a' }}>
                        <div className="logo-icon" style={{ paddingLeft: "10px" }}>
                            <img src="/assets/logo_icon.png" alt="docly" />
                        </div>
                        <Flexbox alignItems="center" justifyContent="space-between" className="laptop_nav">
                            {laptop_nav.map((nav, index) => (
                                <div key={index} onClick={nav.action} style={{ marginRight: '30px', padding: '10px 30px', borderRadius: '100px', backgroundColor: condition(nav.slug) ? '#f0f0f0' : 'transparent' }} className="pointer hoverup" >
                                    <Flexbox alignItems="center">
                                        <img src={nav.icon} alt="octakode docly" />
                                        <Spacebox padding="2px" />
                                        <span>{nav.name}</span>
                                    </Flexbox>
                                </div>
                            ))}
                        </Flexbox>
                        <Flexbox alignItems="center" justifyContent="flex-end" className="pointer avatar-header" style={{ padding: '5px 10px', borderRadius: '100px', backgroundColor: '#f8f8f8' }}>
                            <Avatar sx={{ background: 'var(--secondary)', fontSize: '16px' }}>
                                {user.firstname[0].toUpperCase() + "" + user.lastname[0].toUpperCase()}
                            </Avatar>
                            <IconButton className={showMenu ? "" : "slow-hide"} onClick={() => setShowMenu(!showMenu)}>
                                {showMenu ? <img src="/svgs/close.svg" alt="close" style={{ width: '24px', opacity: .5 }} /> : <img src="/svgs/menu.svg" alt="menu" style={{ width: '24px', opacity: .5 }} />}
                            </IconButton>
                        </Flexbox>
                    </Flexbox>
                </Spacebox>
            </div>
            {showMenu && <Spacebox padding="20px" className="custom-menu">
                <Flexbox alignItems="center">
                    <div onClick={() => navigate('/dashboard/settings')} className="pointer">
                        <Avatar sx={{ background: 'var(--secondary)' }}>
                            {isMobile && <span style={{fontSize: 16}}>{user.firstname[0].toUpperCase() + "" + user.lastname[0].toUpperCase()}</span>}
                            {!isMobile && <span>{user.firstname[0].toUpperCase() + "" + user.lastname[0].toUpperCase()}</span>}
                        </Avatar>
                    </div>
                    <Spacebox padding="10px" />
                    <div>
                        <Typography style={{ fontWeight: '700', fontSize: '20px' }}>
                            {user.firstname + " " + user.lastname}
                        </Typography>
                        <Typography style={{ fontSize: '15px' }}>
                            {user.trial ? user.email + " • Trial ends " + (new Date(user.trial_expires)).toString().substring(0, 15) : user.email}
                        </Typography>

                    </div>
                </Flexbox>
                <Spacebox padding="10px" />
                <CustomButton backgroundColor="var(--primary)" color="white" borderRadius="100px" padding="15px" handleClick={() => {
                    navigate('/dashboard/plans')
                    setShowMenu(false)
                }} className="fullwidth">
                    View Plan
                </CustomButton>
                {menu_nav.map((menu, index) => (
                    <div key={index} onClick={menu.action}>
                        <Spacebox padding="20px" />
                        <Flexbox alignItems="center" className="pointer">
                            <img src={menu.icons} alt="memblify" style={{ width: 20 }} />
                            <Spacebox padding="5px" />
                            <span style={{ fontWeight: '500', fontSize: '14px' }}>{menu.name}</span>
                        </Flexbox>
                    </div>
                ))}
            </Spacebox>}
            <div className="hide-on-large-only fixed fullwidth bottom0 glass" style={{ border: '0px solid transparent', borderRadius: '0px', zIndex: 10 }}>
                <Spacebox padding="10px">
                    <Flexbox justifyContent="space-between" alignItems="center">
                        {laptop_nav.map((nav, index) => (
                            <div key={index} onClick={nav.action} style={{ marginRight: '10px', padding: '10px', backgroundColor: condition(nav.slug) ? "#f0f0f0" : "transparent", textAlign: 'center', borderRadius: '10px' }}>
                                <img src={nav.icon} alt="memblify" style={{ width: '16px' }} />
                                <Spacebox padding="2px" />
                                <span style={{ fontSize: '12px' }}>{nav.name}</span>
                            </div>
                        ))}
                        <div onClick={() => setShowMenu(!showMenu)} style={{ marginRight: '10px', padding: '10px', backgroundColor: (window.location.href).split("//")[1].split("/")[2] === 'settings' ? "#f0f0f0" : "transparent", textAlign: 'center' }} className="pointer">
                            <Avatar sx={{ width: '26px', height: '26px' }}>
                                <small style={{ fontSize: '12px' }}>
                                    {user.firstname[0].toUpperCase() + "" + user.lastname[0].toUpperCase()}
                                </small>
                            </Avatar>
                            <Spacebox padding="2px" />
                            <span style={{ fontSize: '12px' }}>More</span>
                        </div>
                    </Flexbox>
                </Spacebox>
            </div>
            {showRefer && <Flexbox alignItems="center" justifyContent="center" style={{ position: 'fixed', width: '100%', height: '100%', backgroundColor: '#00000050', zIndex: 10 }}>
                <div style={{ width: isMobile ? "95%" : '400px', background: '#ffffff', borderRadius: '20px', margin: "auto" }}>
                    <Spacebox padding="40px">
                        <Typography variant="h6" className="bold" sx={{ textAlign: 'center', lineHeight: '1.2em' }}>
                            Become An Affiliate
                        </Typography>
                        <Spacebox padding="5px" />
                        <Typography sx={{ textAlign: 'center', fontSize: 18, }}>
                            As an affiliate you qualify to earn with Octakode Docly.
                        </Typography>
                    </Spacebox>
                    <Divider light />
                    <Flexbox alignItems="center" justifyContent="space-between">
                        <Spacebox padding="20px" style={{ width: '100%' }}>
                            <CustomButton backgroundColor="transparent" color="red" borderRadius="0px" padding="0" handleClick={() => {
                                setShowRefer(false)
                            }} className="fullwidth">
                                Cancel
                            </CustomButton>
                        </Spacebox>
                        <Divider light orientation="vertical" flexItem />
                        <Spacebox padding="20px" style={{ width: '100%' }}>
                            <CustomButton backgroundColor="transparent" color="var(--secondary)" borderRadius="0px" padding="0" handleClick={() => {
                                handleAffiliate()
                            }} className="fullwidth">
                                {loading ? "Please Wait..." : "Proceed"}
                            </CustomButton>
                        </Spacebox>
                    </Flexbox>
                </div>
            </Flexbox>}
            {showReport && <Flexbox alignItems="center" justifyContent="center" style={{ position: 'fixed', width: '100%', height: '100%', backgroundColor: '#00000050', zIndex: 10 }}>
                <div style={{ width: isMobile ? "95%" : '400px', background: '#ffffff', borderRadius: '20px', margin: "auto" }}>
                    <Spacebox padding={isMobile ? "40px 25px" : "40px"}>
                        <Typography variant="h6" className="bold" sx={{ textAlign: 'center', lineHeight: '1.2em' }}>
                            Report An Issue
                        </Typography>
                        <Spacebox padding="5px" />
                        <Typography sx={{ textAlign: 'center', fontSize: 18, }}>
                            Let us know what issue you are experiencing
                        </Typography>
                        <Spacebox padding="10px" />
                        <textarea
                            type="text"
                            value={report}
                            onChange={(e) => setReport(e.target.value)}
                            placeholder="Make a report..."
                            style={{ height: '100px' }}
                        ></textarea>
                    </Spacebox>
                    <Divider light />
                    <Flexbox alignItems="center" justifyContent="space-between">
                        <Spacebox padding="20px" style={{ width: '100%' }}>
                            <CustomButton backgroundColor="transparent" color="red" borderRadius="0px" padding="0" handleClick={() => {
                                setShowReport(false)
                            }} className="fullwidth">
                                Cancel
                            </CustomButton>
                        </Spacebox>
                        <Divider light orientation="vertical" flexItem />
                        <Spacebox padding="20px" style={{ width: '100%' }}>
                            <CustomButton backgroundColor="transparent" color="var(--secondary)" borderRadius="0px" padding="0" handleClick={() => {
                                handleReport()
                            }} className="fullwidth">
                                {loading ? "Please wait... " : "Report"}
                            </CustomButton>
                        </Spacebox>
                    </Flexbox>
                </div>
            </Flexbox>}
        </div>
    );
}

export default Header;