import { Container, IconButton, Typography } from "@mui/material";
import OnAuth from "../components/onAuth";
import Toast from "../components/Toast";
import { useState } from "react";
import useFetch from "../hooks/useFetch";
import cookies from "../utilities/Cookies";
import Spacebox from "../components/styles/Spacebox";
import Flexbox from "../components/Flexbox";
import { Jelly } from "@uiball/loaders";
import { useSelector } from "react-redux";
import CustomGrid from "../components/styles/Grid";
import { Close, DateRangeOutlined } from "@mui/icons-material";
import CustomButton from "../components/styles/Custombutton";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import ArrowPagination from "../components/ArrowPagination";

const Templates = ({ title }) => {


    document.querySelector("title").innerHTML = title
    const user = useSelector(state => state.user.value)
    const token = cookies.getCookies('token')
    const { data: templates, error, isLoading } = useFetch(process.env.REACT_APP_SERVER + `/get-templates?token=${token}&uid=${user.id}`)

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [filteredTemplate, setFilteredTemplate] = useState(null)

    const UseTemplate = () => {
        setLoading(true)
        fetch(process.env.REACT_APP_SERVER + '/create-document?token=' + token, {
            mode: 'cors',
            method: 'POST',
            headers: { "Content-Type": "application/json", "Accept": "application/json", "Origin": "http://localhost:3000" },
            body: JSON.stringify({
                title: selectedTemplate.title + " [Template]", body: selectedTemplate.body, sheets: selectedTemplate.sheets, draft: true
            })
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    setToastMsg("Draft saved")
                    setOpen(true)
                    setSeverity('success')
                    setLoading(false)
                    setTimeout(() => {
                        navigate('/dashboard/manage')
                    }, 3000);
                } else {
                    console.log(res.error)
                    setToastMsg(res.msg)
                    setOpen(true)
                    setSeverity('error')
                    setLoading(false)
                }
            }).catch(err => {
                console.log("Error message: ", err.message)
                setToastMsg('An error occured')
                setOpen(true)
                setSeverity('error')
                setLoading(false)
            })
    }

    return (
        <OnAuth>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Container className="templates-body">
                <Typography variant="h5" className="bold" style={isMobile ? { fontSize: 25 } : {}}>
                    Manage Custom Contract Templates
                </Typography>
                <Typography sx={{ fontSize: '18px' }}>
                    Templates
                </Typography>
                {error && (
                    <div>
                        <Spacebox padding="20px" />
                        <Typography>An error occured</Typography>
                    </div>
                )}
                {isLoading && (
                    <div>
                        <Spacebox padding="20px" />
                        <Flexbox justifyContent="center">
                            <Jelly size={20} color="var(--primary)" />
                        </Flexbox>
                    </div>
                )}
                {/* show all templates */}
                {templates && (<div>
                    {templates.length > 0 ? <div>
                        <Spacebox padding="20px" />
                        {filteredTemplate && <CustomGrid grid={isMobile ? 1 : 4} gap={"20px"}>
                            {filteredTemplate.map((template, index) => (
                                <div className="pointer hoverup" key={index} style={{ overflow: 'hidden', borderRadius: '10px', boxShadow: '0px 10px 20px #00000021' }} onClick={() => setSelectedTemplate(template)}>
                                    <div style={{ padding: '20px', background: '#f1f1f1', height: '150px', overflow: 'hidden' }}>
                                        <div className="mozilla-zoom" dangerouslySetInnerHTML={{ __html: template.body }}></div>
                                    </div>
                                    <div style={{ padding: '20px' }}>
                                        <Typography sx={{ fontSize: '20px' }}>
                                            {template.title}
                                        </Typography>
                                        <Flexbox alignItems="center" style={{ opacity: '.5' }}>
                                            <DateRangeOutlined style={{ fontSize: '12px' }} />
                                            <Spacebox padding="3px" />
                                            <small>{(new Date(template.timestamp)).toString().substring(0, 15)}</small>
                                        </Flexbox>
                                    </div>
                                </div>
                            ))}
                        </CustomGrid>}
                    </div> : <div>
                        No Templates found
                    </div>}
                    <Spacebox padding="10px" />
                    <ArrowPagination data={templates} limit={4} setShowData={setFilteredTemplate} />
                </div>)}
                {selectedTemplate && <Flexbox justifyContent="center" alignItems="center" className="preview-box">
                    <div>
                        <div className="preview">
                            <div style={{ textAlign: 'right' }}>
                                <IconButton onClick={() => setSelectedTemplate(null)}>
                                    <Close />
                                </IconButton>
                            </div>
                            <Spacebox padding="10px" />
                            <div className="doc-preview" dangerouslySetInnerHTML={{ __html: selectedTemplate.body }}></div>
                            <Spacebox padding="20px" />
                            <Flexbox justifyContent="center">
                                <CustomButton backgroundColor="var(--primary)" borderRadius="100px" color="white" handleClick={() => UseTemplate()} padding="15px 40px" className="fullwidth">
                                    {loading ? (<Flexbox justifyContent="center">
                                        <Jelly size={15} color="white" />
                                    </Flexbox>) : "Use Template"}
                                </CustomButton>
                            </Flexbox>
                        </div>
                    </div>
                </Flexbox>}
            </Container>
        </OnAuth>
    );
}

export default Templates;