import { Container, IconButton, Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import CustomGrid from "../components/styles/Grid";
import cookies from "../utilities/Cookies";
import useFetch from "../hooks/useFetch";
import Flexbox from "../components/Flexbox";
import OnAuth from "../components/onAuth";
import { Jelly } from '@uiball/loaders';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import CustomButton from "../components/styles/Custombutton";
import Toast from "../components/Toast";
import { updateuser } from "../features/users";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";


const Dashboard = ({ title }) => {

    document.querySelector("title").innerHTML = title
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const token = cookies.getCookies('token');
    const user = useSelector(state => state.user.value)
    const [showIndustry, setShowIndustry] = useState(false)
    const industries = [
        "Accounting & Tax",
        "Business Services / Consulting",
        "Construction",
        "Education",
        "Financial Services",
        "Government",
        "Healthcare - Health Plans & Payers",
        "Healthcare - Providers",
        "Insurance",
        "Legal",
        "Life Sciences",
        "Manufacturing",
        "Mortgage",
        "Not For Profit",
        "Real Estate - Commercial",
        "Real Estate - Residential",
        "Retail",
        "Student",
        "Technology",
        "Other"
    ]
    const company_sizes = [
        "1 - 5", "6 - 10", "11 - 50", "51 - 100", "100+"
    ]

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [industry, setIndustry] = useState('Accounting & Tax')
    const [companySize, setCompanySize] = useState('1 - 5')
    const [companyName, setCompanyName] = useState('')
    const [companyAddress, setCompanyAddress] = useState('')
    const [companyState, setCompanyState] = useState('')
    const [companyZipCode, setCompanyZipCode] = useState('')
    const [companyCountry, setCompanyCountry] = useState('')
    const [brandingPosition, setBrandingPosition] = useState('top right')
    const [isLoading, setIsLoading] = useState(false)

    const { data: documents, isLoading: loading, error } = useFetch(process.env.REACT_APP_SERVER + `/get-documents?uid=${user.id}&token=${token}`)


    useEffect(() => {
        if (!user.industry) {
            slidebar(true)
        }
        // eslint-disable-next-line
    }, [])

    const slidebar = (bool) => {
        if (bool) {
            setShowIndustry(bool)
            setTimeout(() => {
                document.querySelector('.slide-bar-bg').classList.remove('hide')
                document.querySelector('.slide-bar-bg').classList.add('show')
                setTimeout(() => {
                    document.querySelector('.slide-bar').classList.add('show')
                }, 200);
            }, 500);
        } else {
            document.querySelector('.slide-bar').classList.remove('show')
            setTimeout(() => {
                document.querySelector('.slide-bar-bg').classList.add('hide')
                document.querySelector('.slide-bar-bg').classList.remove('show')
                setShowIndustry(bool)
            }, 500);
        }
    }

    const handleOnboarding = () => {
        setIsLoading(true)
        fetch(process.env.REACT_APP_SERVER + '/update-user-details?token=' + token, {
            method: 'POST',
            mode: 'cors',
            headers: { "Content-Type": "application/json", "Accept": "application/json", "Origin": 'http://localhost:3000' },
            body: JSON.stringify({ 
                industry, companySize,
                company_address: companyAddress,
                company_name: companyName,
                company_state: companyState,
                company_zipcode: companyZipCode,
                company_country: companyCountry,
                branding_position: brandingPosition
            })
        }).then(res => res.json())
            .then(res => {
                if (res.success) {

                    dispatch(updateuser({ ...user, industry, companySize }))

                    slidebar(false)
                    setToastMsg("Account Updated")
                    setOpen(true)
                    setSeverity('success')
                    setIsLoading(false)
                } else {
                    console.log(res.error)
                    setToastMsg(res.msg)
                    setOpen(true)
                    setSeverity('error')
                    setIsLoading(false)
                }
            }).catch(err => {
                console.log("Error message: ", err.message)
                setToastMsg('An error occured')
                setOpen(true)
                setSeverity('error')
                setIsLoading(false)
            })
    }

    const handleDocCreate = () => {
        if (user.accesstoken) {
            navigate('/dashboard/create')
        } else {
            setIsLoading(true)
            // verify with google
            const redirect = 'https://docly.octakode.xyz/dashboard/oauth2callback'
            fetch(process.env.REACT_APP_SERVER + '/update-google-auth?redirect_url=' + redirect + "&token=" + token)
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        setIsLoading(false)
                        window.location.href = res.url;
                    } else {
                        console.log(res.error)
                        setToastMsg(res.msg)
                        setOpen(true)
                        setSeverity('error')
                        setIsLoading(false)
                    }
                }).catch(err => {
                    console.log("Error message: ", err.message)
                    setToastMsg('An error occured')
                    setOpen(true)
                    setSeverity('error')
                    setIsLoading(false)
                })
        }
    }

    const checkLastLive = (documents) => {
        let passed = []
        let thirtyDays = 30 * (24 * 60 * 60 * 1000)
        let today = Date.now()
        for (let x in documents) {
            if (documents[x].last_loaded) {
                let lastLoaded = documents[x].last_loaded + thirtyDays
                if (lastLoaded > today) {
                    passed.push(documents[x].last_loaded)
                }
            }
        }
        return passed.length
    }

    return (
        <OnAuth>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Container className="dashboard-page">
                <Typography variant="h5" className="bold">Welcome</Typography>
                <Typography variant="subtitle2" style={{ color: 'grey', fontSize: '16px' }}>
                    Here are your all time stat
                </Typography>
                <Spacebox padding="20px" />
                {error && (<Typography>An error occured</Typography>)}
                {loading && (
                    <Flexbox justifyContent="center">
                        <Jelly size={20} color="var(--primary)" />
                    </Flexbox>
                )}
                {documents && (
                    <CustomGrid grid={isMobile ? 2 : 4} gap={isMobile ? "10px" : "20px"} >
                        <div style={isMobile ? { background: "#00000010", padding: "10px", borderRadius: "10px" } : {}}>
                            <Typography sx={{ textAlign: isMobile ? "center" : "left", fontSize: isMobile ? "50px" : '70px' }} className="bold">
                                {(documents.filter(doc => doc.active)).length}
                            </Typography>
                            <Spacebox padding="5px" />
                            <Typography sx={{ fontSize: 15, textAlign: isMobile ? "center" : "left" }}>
                                Active Documents
                            </Typography>
                        </div>
                        <div style={isMobile ? { background: "#00000010", padding: "10px", borderRadius: "10px" } : {}}>
                            <Typography sx={{ textAlign: isMobile ? "center" : "left", fontSize: isMobile ? "50px" : '70px' }} className="bold">
                                {(documents.filter(doc => !(doc.active))).length}
                            </Typography>
                            <Spacebox padding="5px" />
                            <Typography sx={{ fontSize: 15, textAlign: isMobile ? "center" : "left" }}>
                                Inactive Documents
                            </Typography>
                        </div>
                        <div style={isMobile ? { background: "#00000010", padding: "10px", borderRadius: "10px" } : {}}>
                            <Typography sx={{ textAlign: isMobile ? "center" : "left", fontSize: isMobile ? "50px" : '70px' }} className="bold">
                                {checkLastLive(documents)}
                            </Typography>
                            <Spacebox padding="5px" />
                            <Typography sx={{ fontSize: 15, textAlign: isMobile ? "center" : "left" }}>
                                Live Documents
                            </Typography>
                        </div>
                        <div style={isMobile ? { background: "#00000010", padding: "10px", borderRadius: "10px" } : {}}>
                            <Typography sx={{ textAlign: isMobile ? "center" : "left", fontSize: isMobile ? "50px" : '70px' }} className="bold">
                                {(documents.filter(doc => doc.draft)).length}
                            </Typography>
                            <Spacebox padding="5px" />
                            <Typography sx={{ fontSize: 15, textAlign: isMobile ? "center" : "left" }}>
                                Pending Documents
                            </Typography>
                        </div>
                    </CustomGrid>
                )}
                <Spacebox padding="20px" />
                <div style={{ margin: 'auto', background: '#f8f8f8', borderRadius: '20px' }} className="seventy-percent">
                    <Spacebox padding="20px">
                        <Typography sx={{ textAlign: 'center', fontSize: '25px' }}>
                            Create A new document by clicking the "Create" button
                        </Typography>
                        <Spacebox padding="10px" />
                        <Flexbox justifyContent="center">
                            <CustomButton backgroundColor="var(--primary)" borderRadius="100px" color="white" handleClick={handleDocCreate} padding="10px 40px">
                                {"Create"}
                            </CustomButton>
                        </Flexbox>
                    </Spacebox>
                </div>

            </Container>
            {showIndustry &&
                <div className="slide-bar-bg hide"
                    style={{ top: 0, height: `100vh` }}
                >
                    <div className="slide-bar">
                        <Flexbox justifyContent="space-between" alignItems="center">
                            <Typography className="bold">Complete Account</Typography>
                            <IconButton onClick={() => slidebar(false)}>
                                <Close />
                            </IconButton>
                        </Flexbox>
                        <Spacebox padding="10px" />
                        <small>Industry</small>
                        <Spacebox padding="2px" />
                        <select
                            name="industry"
                            id="industry"
                            onChange={(e) => setIndustry(e.target.value)}
                        >
                            {industries.map((industry, index) => (
                                <option value={industry} key={index}>{industry}</option>
                            ))}
                        </select>
                        <Spacebox padding="10px" />
                        <small>Company Size</small>
                        <Spacebox padding="2px" />
                        <select
                            name="company-size"
                            id="company-size"
                            onChange={(e) => setCompanySize(e.target.value)}
                        >
                            {company_sizes.map((size, index) => (
                                <option value={size} key={index}>{size}</option>
                            ))}
                        </select>
                        <Spacebox padding="10px" />
                        <small>Company Name</small>
                        <Spacebox padding="2px" />
                        <input
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            placeholder="Company Name"
                        />
                        <Spacebox padding="10px" />
                        <small>Company Address</small>
                        <Spacebox padding="2px" />
                        <input
                            value={companyAddress}
                            onChange={(e) => setCompanyAddress(e.target.value)}
                            placeholder="Address"
                        />
                        <Spacebox padding="10px" />
                        <small>Company State</small>
                        <Spacebox padding="2px" />
                        <input
                            value={companyState}
                            onChange={(e) => setCompanyState(e.target.value)}
                            placeholder="State"
                        />
                        <Spacebox padding="10px" />
                        <small>Company Zipcode</small>
                        <Spacebox padding="2px" />
                        <input
                            value={companyZipCode}
                            onChange={(e) => setCompanyZipCode(e.target.value)}
                            placeholder="Zip Code"
                        />
                        <Spacebox padding="10px" />
                        <small>Company Country</small>
                        <Spacebox padding="2px" />
                        <input
                            value={companyCountry}
                            onChange={(e) => setCompanyCountry(e.target.value)}
                            placeholder="Country"
                        />
                        <Spacebox padding="10px" />
                        <small>Brand Position</small>
                        <Spacebox padding="2px" />
                        <select
                            value={brandingPosition}
                            onChange={(e) => setBrandingPosition(e.target.value)}
                        >
                            <option value="top right">Top Right</option>
                            <option value="top left">Top Left</option>
                            <option value="bottom right">Bottom Right</option>
                            <option value="bottom left">Bottom Left</option>
                        </select>
                        <Spacebox padding="20px" />
                        <CustomButton backgroundColor="var(--primary)" borderRadius="100px" color="white" handleClick={handleOnboarding} padding="15px" className="fullwidth">
                            {isLoading ? (<Flexbox justifyContent="center">
                                <Jelly size={15} color="white" />
                            </Flexbox>) : "Proceed"}
                        </CustomButton>
                    </div>
                </div>}
        </OnAuth>
    );
}

export default Dashboard;